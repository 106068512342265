import { Component, OnInit } from '@angular/core';
import { APP_URLS, TenantId } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CommonService } from 'src/app/services/common.service';

declare let $: any;
@Component({
  selector: 'app-home-professional-courses',
  templateUrl: './home-professional-courses.component.html',
  styleUrls: ['./home-professional-courses.component.css']
})
export class HomeProfessionalCoursesComponent implements OnInit {

  CoursesData = [];
  student_Id: any
  showDialogReg = false;
  CartList = [];
  FavList = [];
  ID: string;
  constructor(public service: CourseService,
    private route: ActivatedRoute,
    private userservice: UserService,
    private router: Router,
  ) {

  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    autoHeight: true,
    pullDrag: true,
    mouseDrag: true,
    autoWidth: true,
    navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      740: { items: 3 },
      940: { items: 3 },
      1000: { items: 1 }
    }, nav: true
  }


  ngOnInit() {
    //get local storage of add cart
    this.getData()
    let data = JSON.parse(localStorage.getItem('userDetails'))
    if (data) {
      this.student_Id = data._id;
    }
    // if(JSON.parse(localStorage.getItem('userDetails'))._id){
    // this.student_Id= JSON.parse(localStorage.getItem('userDetails'))._id;
    // }
    $('.inx-owl-slide').owlCarousel({
      loop: true,
      margin: 5,
      nav: true,
      autoplay: true,
    });
    $(function () {
      $(".inx-owl-slide .owl-next").addClass("fas fa-chevron-right");
      $(".inx-owl-slide .owl-prev").addClass("fas fa-chevron-left");
    });
  }

  getData() {
    let dataofcart = localStorage.getItem('cartdata');
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',')
    }

    let dataofFav = localStorage.getItem('favdata');
    if (dataofFav != null) {
      this.FavList = dataofFav.split(',')
    }


    this.getCourseList();

  }

  getCourseList() {
    let url = APP_URLS.latestcourse;
    this.service.getCallwithHeader(url).subscribe((res: any) => {

      if (res.all.length > 0) {
        res.all.forEach(x => {
          if (this.CartList.includes(x._id)) {
            x['incart'] = true
          }
          else {
            x['incart'] = false
          }

          if(this.FavList.includes(x._id)){
            x['infav'] = true
          }
          else {
            x['infav'] = false
          }
          // if(this.student_Id){
          //  if(x.favorites.length>0){
          //    x.favorites.forEach(y => {
          //      if(y==this.student_Id)
          //      {
          //        x.Favouritebutton="Remove Favourite"
          //      }
          //      else{
          //        x.Favouritebutton="Add As Favourite"
          //      }
          //    });
          //   }
          //   else{
          //    x.Favouritebutton="Add As Favourite"
          //   }
          //    if (x.applications.length > 0) {
          //    x.applications.forEach(y => {
          //      if (y == this.student_Id) {
          //        x.Applybutton = "Remove From Cart"
          //      }
          //      else {
          //        x.Applybutton = "Add To Cart"
          //      }
          //    });
          //  }
          //  else {
          //    x.Applybutton = "Add To Cart"
          //  }
          // }
          // else{
          // x.Favouritebutton = "Add As Favourite"
          // x.Applybutton = "Apply"
          // }
        });
        this.CoursesData = res.all

      }


    })
  }

  selectCourse(data) {
    this.service.selectCourseById(data);
    this.service.senddataToHeader(data);
    this.router.navigate(['/' + data.slug])
  }

  AddFav(data) {
    // let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';
   
    if (this.student_Id) {
      let favarry = []
      let url = APP_URLS.favmulti
      favarry.push(data._id) 
      let payload = {
        courseids : favarry,
        target :"add"
      }
      this.service.putCall(url, payload)
        .subscribe((data: any) => {

          if(data.suc_arr.length > 0){
            this.userservice.showSuccess(data.suc_arr[0]);
          }
          if(data.err_arr.length > 0){
            this.userservice.showError(data.err_arr[0]);
          }
          this.getData();
        }, err => {

        })
    } else {

      // this.userservice.showError('Please register or login to continue');
      // this.service.selectedcourse(data._id, "favourite")
      // this.showDialogReg = true;
this.addtofavLocalStorage(data)

    }

  }

  AddApply(data) {
    let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';

    if (this.student_Id) {
      this.service.putCall(url, {})
        .subscribe((data: any) => {

          this.userservice.showSuccess(data.respMessage);
          this.getCourseList()
        }, err => {

        })
    } else {
      this.userservice.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, "apply")
      this.showDialogReg = true;
    }

  } 

  addToCart(course) {

    let url = APP_URLS.addtocart + course._id + '?addtocart=true';

    if (this.student_Id) {
      this.service.putCall(url, {})
        .subscribe((data: any) => {
          if (data.suc_arr.length > 0) {
            this.userservice.showSuccess(data.suc_arr[0]);
            this.userservice.setCart('data')
          }
          if (data.err_arr.length > 0) {
            this.userservice.showError(data.err_arr[0]);
          }

          this.getCourseList()
        }, err => {

        })
    } else {
      this.addtoLocalStorage(course);
    }
  }

  addtoLocalStorage(course) {
    this.CartList.push(course._id);
    localStorage.setItem("cartdata", this.CartList.toString())
    this.userservice.setCart('data')
    this.userservice.showSuccess("Course Successfully added to Your Cart");
    this.getData();
  }

  goToCart() {
    this.router.navigate(['/Cart'])
  }


  addtofavLocalStorage(course) {
    this.FavList.push(course._id);
    localStorage.setItem("favdata", this.FavList.toString())
    // this.userservice.setCart('data')
    this.userservice.showSuccess("Course Successfully added to Favourite List");
    this.getData();
  }

  removeFav(course){ 
    if (this.student_Id) {
      let favarry = []
      let url = APP_URLS.favmulti
      favarry.push(course._id) 
      let payload = {
        courseids : favarry,
        target :"remove"
      }
      this.service.putCall(url, payload)
        .subscribe((data: any) => {
          if(data.suc_arr.length > 0){
            this.userservice.showSuccess(data.suc_arr[0]);
          }
          if(data.err_arr.length > 0){
            this.userservice.showError(data.err_arr[0]);
          }
          this.getData();
        }, err => {

        })
    } else {
    this.FavList.forEach((x, i) => {
      if (x == course._id) {
        this.FavList.splice(i, 1);
        let data = this.FavList;
        if(data.length > 0){
          localStorage.setItem('favdata', data.toString())
        }
        if(data.length == 0){
          localStorage.removeItem('favdata')
        }
       
        this.userservice.showSuccess("Removed from Favourite List");
        // this.userservice.setCart("cart")
        this.getData()
      
      }
    })
  }
  }


  Chapters =[];

  openCourse(Training) {
    localStorage.setItem('courseId', Training._id)
    localStorage.setItem('TrainingName', Training.name)
    localStorage.setItem('uuid', Training.resume_chapter)
    if (Training.apply_status == "assigned" && Training.resume_chapter == '') {
      this.router.navigate(['/Mycourses/', Training._id])
    }
    if (Training.apply_status == "assigned" && Training.resume_chapter != '') {
  
      this.ID = localStorage.getItem('courseId')
      let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
      this.service.getCallwithHeader(url).subscribe(
        (res: any) => {
          this.Chapters = res.cou_overview;
          this.setChaptersData();
        },
        err => {
  
        })
  
    }
  }
  
  FinalChaptersArray = [];
  
  async setChaptersData() {
    await this.Chapters.forEach((x, i) => {
      x['accordion'] = '#accordion' + i
      x['accordionID'] = 'accordion' + i
      x.child.forEach((y, index) => {
        y['childaccordion'] = '#childaccordion' + index
        y['childaccordionID'] = 'childaccordion' + index
        if (y.chapter_type == "lesson") {
          y['icon'] = "fa fa-book"
        }
        if (y.chapter_type == "assignment") {
          y['icon'] = "fa fa-code"
        }
        if (y.chapter_type == "downloads") {
          y['icon'] = "fa fa-cloud-download-alt"
        }
        if (y.chapter_type == "test" || y.chapter_type == "qatest") {
          y['icon'] = "fa fa-pencil-alt"
        }
        if (y.chapter_type == "quiz") {
          y['icon'] = "fa fa-graduation-cap"
        }
        if (y.chapter_type == "chapter") {
          y['icon'] = "fa fa-bars"
        }
      })
      this.FinalChaptersArray.push(x)
      localStorage.setItem('AllChapters', JSON.stringify(this.FinalChaptersArray))
      var Trainingid = localStorage.getItem('courseId')
      var TrainingName = localStorage.getItem('TrainingName')
      var resume_chapter = localStorage.getItem('uuid')
      this.router.navigate(['/Mychapter/', Trainingid + "-" + resume_chapter, TrainingName.replace(/\s/g, '-')])
    })
  }

}
