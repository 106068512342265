import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MyProfileService } from '../../services/my-profile.service';
import { Subscription } from 'rxjs';

declare let $: any;
@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {
  showDialog = false;
  showDialogReg = false;
  showDialogForgot = false;

  sub: any;
  page: number;
  headerClass = "";
  selectCourseId: any;
  selectCourseIdData: any;
  token: boolean = false;

  background_image = "";
  CorseData = [];
  userDetail: any;
  userLoginDetail:any;
  item: any;
  CartCount = 0;
  student_Id = '';
  CartList = [];
  detailRecivedSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private profileSvc: MyProfileService,
    private toastr: ToastrService) {
      this.background_image = "../../../assets/images/banner.png";
      this.detailRecivedSubscription = this.userSvc.getCart().subscribe(data => {
        if (data) {
          this.ngOnInit()
        }
      })
  }

  ngOnDestroy() { 
    this.detailRecivedSubscription.unsubscribe();
  }
  
  getProfileDetails() {
    //this.cms.loaderSet(true);
    let url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
    this.profileSvc.getCallWithHeaders(url)
    .subscribe((res: any) => {
      //this.cms.loaderSet(false);
      this.userDetail = res.userDetails;
      this.showDialog = false;
      localStorage.setItem('profilesummaryData', JSON.stringify(this.userDetail))
    },err=>{
      //this.cms.loaderSet(false);
    })
  }

  userRole = '';
  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    this.userLoginDetail = JSON.parse(localStorage.getItem('userDetails'))
    if (this.userLoginDetail) {
      this.student_Id = this.userLoginDetail._id;
    }
    if (localStorage.getItem('token') && this.student_Id ) {
      this.token = true
    }

    if( this.userRole != 'employer'){
      let dataofcart = localStorage.getItem('cartdata')
      this.CorseData = [];
      this.CartList = [];
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',')
        this.CartCount = this.CartList.length;
        // this.addtocartCall();
      }
      this.getCart()
      this.getCourseList();
    }

    var profileData = localStorage.getItem('profilesummaryData');
    if( profileData != undefined && typeof profileData == 'string' ){
      this.userDetail = JSON.parse(profileData);
      if( this.userDetail == null ){
        this.getProfileDetails();
      }
    }

    this.userSvc.getIsLogin().subscribe(data => {
      this.token = data;
      if( this.userRole != 'employer')
        this.getCourseList();
    })

    this.profileSvc.getForgotPop()
      .subscribe((data: any) => {
        if (data == 'forgot') {
          this.showDialog = false;
          this.showDialogForgot = true;
          this.showDialogReg = false;
        }
        if (data == 'register') {
          this.showDialog = false;
          this.showDialogForgot = false;
          this.showDialogReg = true;
        }
        if (data == 'login') {
          this.showDialog = true;
          this.showDialogForgot = false;
          this.showDialogReg = false;
        }
      })
    this.profileSvc.getPopup()
      .subscribe((data: any) => {
        this.showDialogReg = false;
      })

    this.selectCourseId = this.service.getDataToHeader()
      .subscribe((data: any) => {
        this.selectCourseIdData = data.text;
      });
    this.sub = this.route.queryParams.subscribe(params => {
      this.page = +params['page'] || 0;
      if (this.page == 0) {
        this.headerClass = "header1"
      }
      else if (this.page == 1) {
        this.headerClass = "header inner-ban bg-imag-abt"
      }
      else if (this.page == 2) {
        this.headerClass = "header inner-ban"
      }
      else if (this.page == 3) {
        this.headerClass = "header inner-ban"
      }
      else if (this.page == 4) {
        this.headerClass = "header inner-ban"
      }
      else if (this.page == 5) {
        this.headerClass = "header inner-ban"
      }
      $(function () {
        $(window).on("scroll", function () {
          if ($(window).scrollTop() > 1) {
            $(".top-header").addClass("top-fix");
          } else {
            $(".top-header").removeClass("top-fix");
          }
        });
      });
      $(function () {
        $(window).on("scroll", function () {
          if ($(window).scrollTop() > 1) {
            $(".banner-carousel").addClass("padding-set-scroll");
          } else {
            $(".banner-carousel").removeClass("padding-set-scroll");
          }
        });
      });
    });
  }
 logout(){
   localStorage.clear();
   this.userSvc.setIsLogin(false);
   this.router.navigate(['/home/'],{ queryParams: {  page: 0}})
  //  setTimeout(function(){
  //   location.reload(); }, 1500);

 }

  getCourseList() {
    this.CorseData = []
    let url = APP_URLS.Categories;
    this.service.getCall(url).subscribe((res: any) => {

      var name = [];
      var id = ''
      var categories = Object.values(res.course_category_detail)
      categories.forEach((x: any) => {
        var obj = {
          course_type: '',
          description: '',
          courses: []

        }
        const repetingdata = name.find(z => z == res.course_category_detail.course_type)
        if (repetingdata) {
        }
        else {
          obj.course_type = x.title;
          obj.description = x.description;
          if (x.course) {
            x.course.forEach(y => {
              if (x.title == y.course_type) {
                obj.courses.push({
                  id: y._id,
                  name: y.name,
                  slug: y.slug,

                })
              }

            })
            this.CorseData.push(obj)
          } else {
            //this.CorseData.push(obj)
          }
        }
      })
    })
  }

  openCourses(coursetype) {
    localStorage.setItem('courseType', coursetype.course_type)
    this.router.navigate(['/Courses/' + coursetype.course_type.replace(/\s/g, '-')])
  }

  openIndividulaCourse(course) {

    localStorage.setItem('Individualcourse', course.slug)
    localStorage.setItem('IndividualcourseId', course.id)
    this.router.navigate(['/' + course.slug])
  }
  profile() {
    if( this.userRole == 'employer'){
      this.router.navigate(['/employer-profile/emp-profile-summary'])
    } else {
      this.router.navigate(['/profile/profile-summary'])
    }
  }

  mywork() {
    if( this.userRole == 'employer'){
      this.router.navigate(['/employer-mywork/emp-my-project'])
    } else {
      this.router.navigate(['/student-my-work/student-work-summary'])
    }
  }

  openCart() {
    this.router.navigate(['/Cart'])
  }

  getCart() {

    if (localStorage.getItem('token') && this.student_Id ) {
      let url = APP_URLS.getcart;
      let payload = {}
      this.userSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
        this.CartCount = res.listCount;
      })
    } else {
      let dataofcart = localStorage.getItem('cartdata')
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',');
        this.CartCount = this.CartList.length;
        // this.addtocartCall();
      }
    }
    // else {
    //   let url = APP_URLS.getcart;
    //   let payload = {
    //     courseids: this.CartList,
    //     tenantId: TenantId
    //   }
    //   this.userSvc.postCall(url, payload).subscribe((res: any) => {

    //     this.CartCount = res.listCount;


    //   })
    // }

    // let url = APP_URLS.getcart;
    // let payload = {}
    //  this.userSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
    //   this.CartCount = res.listCount
    // })

  }
  home() {
    if (localStorage.getItem('token')) {
      this.token = true

    }
    this.router.navigate(['/home'])
    // setTimeout(() => { location.reload(); }, 100);


  }

}
