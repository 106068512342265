import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// import html2canvas from 'html2canvas';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { CommonService } from 'src/app/services/common.service';
import { APP_URLS, tenantInfo } from '../../Models/url';
import * as moment from 'moment';

// import * as jsPDF from 'jspdf';
import html2PDF from 'jspdf-html2canvas';


@Component({
    selector: 'app-opencertificate',
    templateUrl: './opencertificate.component.html',
    styleUrls: ['./opencertificate.component.css']
})

export class OpencertificateComponent implements OnInit {
    template: any;
    certificateTemplate: any;
    @Input() turl = location.href;
    @Input() text = '';
 
    data = {
        studentname: '',
        course: '',
        termName :''
    };
    templateList:any;
    cerTemplate:any;
    constructor(public service: MyProfileService, private sanitizer: DomSanitizer, private router: Router,
        public cms: CommonService) {
        // if (!window['fbAsyncInit']) {
        //     window['fbAsyncInit'] = function () {
        //         window['FB'].init({
        //             appId: 'your-app-id',
        //             autoLogAppEvents: true,
        //             xfbml: true,
        //             version: 'v3.0'
        //         });
        //     };
        // }
     
        // const furl = 'https://connect.facebook.net/en_US/sdk.js';        
        // if (!document.querySelector(`script[src='${furl}']`)) {
        //     let script = document.createElement('script');
        //     script.src = furl;
        //     document.body.appendChild(script);
        // }

        // const turl = 'https://platform.twitter.com/widgets.js';
        // if (!document.querySelector(`script[src='${turl}']`)) {
        //     let script = document.createElement('script');
        //     script.src = turl;
        //     document.body.appendChild(script);
        // }
    }

    getCoureDetails(data) {

        let url;
        if( data.parentId ){
            url = APP_URLS.v1.getCourseDetails + '?course=' + data.courseId + '&chapter_parent=' + data.parentId;
        } else {
            url = APP_URLS.v1.getCourseDetails + '?course=' + data.courseId + '&chapter_parent=';
        }

        this.service.getCallWithHeaders(url).subscribe((res: any) => {
            if( data.requestOrg == 'lms' ){
                if( res.chapter.parent.chapter_type == 'independent' ){
                    if( data.examType == 'test'){
                        data.certificate_type = 'Chapter Certificate - Test'
                    } else if( data.examType == 'qatest' ){
                        data.certificate_type = 'Chapter Certificate - Test QA'
                    }
                } else if(data.examType == 'certificate'){
                    data.certificate_type = 'Chapter Completion Certificate'
                } else {
                    if( data.examType == 'test'){
                        data.certificate_type = 'Chapter Completion Certificate - Test'
                    } else if( data.examType == 'qatest' ){
                        data.certificate_type = 'Chapter Completion Certificate - Test QA'
                    }
                }
                data.course = res.details.course.name;
                data.chapterName = res.chapter.parent.name;
            }
            data.termName = ( res.details.courseterm.length ) ? res.details.courseterm[0].name : '';
            this.getNewCertificate( data );
        })
    }

    getEncodedUrl( data ){
        var encodeData = '';
        if( data.signature && data.signature != '' ){
            let url = APP_URLS.v1.getEncodedUrl;
            var Payload = { path : APP_URLS.FILE_PATH_LINODE + "signature/" + data.signature }
            this.service.postCall(url, Payload).subscribe((res: any) => {
                encodeData = 'data:image/jpeg;base64,' + res.encodeData;
                this.generateCertificate( data, encodeData );
            },
            err => {
                this.generateCertificate( data, encodeData );
            })
        } else {
            this.generateCertificate( data, encodeData );
        }
    }

    ngOnInit(): void {

        let data = JSON.parse(localStorage.getItem('certificates'))

        this.data.studentname = data.studentname;
        this.data.course = data.course
        if( data.requestOrg && ( data.requestOrg == 'course_completion' || data.requestOrg == 'lms' )){
            this.getCoureDetails(data);
        } else {
            this.getNewCertificate( data );
        }
        
        // window['FB'] && window['FB'].XFBML.parse();
        // window['twttr'] && window['twttr'].widgets.load();
        // window['insta'] && window['insta'].widgets.load();
    }
    
    getNewCertificate(data) {
        let url = APP_URLS.v1.getAllCertificateTemplates;
        this.service.getCallWithHeaders(url).subscribe((res: any) => {

            this.templateList = res.resTemp;
            this.templateList.forEach((x:any,i)=>{
                if( x.name == data.certificate_type ){
                    this.cerTemplate = x.template_text_view;
                    this.getEncodedUrl( data );
                    return false;
                }
            });
        })
    }

    generateCertificate( data : any, encodeData : any ){
        var snippet = document.createElement("div");
        var certificateTemplate = this.cerTemplate.replace(/\[Signature\]/g, "<img id='auth_sign' src='" + encodeData + "' style='width:100px;'>");
        snippet.innerHTML = certificateTemplate;
        
        Array.from(snippet.querySelectorAll('img')).forEach(function (ele) {

            var text = ele.src
            var text2 = ele.src
            let logo = tenantInfo.logoUrl;
            let backgroundlogo = '../../../assets/images/certificatebg.png'
            let val = location.origin + "/[sitelogo]"
            let val2 = location.origin + "/[backgroundlogo]"

            if (ele.src == val) {
                text = text.replace(text, logo)
                ele.src = text
            }
            if (ele.src == val2) {
                text = text.replace(text2, backgroundlogo)
                ele.src = text
            }
        });

        Array.from(snippet.querySelectorAll('div')).forEach(function (ele) {
            var text = ele.textContent
            var text2 = ele.textContent
            var text3 = ele.textContent
            var text4 = ele.textContent
            var text5 = ele.textContent
            var first_name = JSON.parse(localStorage.getItem('profilesummaryData')).first_name;
            var middle_name = JSON.parse(localStorage.getItem('profilesummaryData')).middle_name;
            var last_name = JSON.parse(localStorage.getItem('profilesummaryData')).last_name;
            var studentID = JSON.parse(localStorage.getItem('userDetails'))._id;
            var DisplayName = first_name + ' ' + middle_name + ' ' + last_name;
            
            const date = new Date()
            if (ele.textContent == "[studentname]") {
                text = text.replace(text, DisplayName)
                ele.textContent = text

            }
            if( ele.textContent == "Certified: [date]" && data.updated ){
                var updateDate = new Date(data.updated).toJSON().slice(0, 10);
                var dArr = updateDate.split("-");  
                var result = dArr[2]+ "/" +dArr[1]+ "/" + dArr[0]; 
                text = text.replace(text3, 'Certified: ' + result);
                ele.textContent = text;
            }

            if (ele.textContent == "Certificate code:[certificateCode]") {
                text = text.replace(text4, "Certificate code: " + studentID)
                ele.textContent = text
            }

            if (ele.textContent == "[taskname] test") {
                text = text.replace(text2, data.course)
                ele.textContent = text
            }

            if (ele.textContent == "[level]") {
              text = text.replace(text5, data.status_level)
              ele.textContent = text
          }

            if (ele.textContent == "[coursename]") {
                text = text.replace(text2, data.course)
                ele.textContent = text
            }

            if (ele.textContent == "Has successfully completed the test in [coursename]") {
                text = text.replace(text2, 'Has successfully completed the Test in ' + data.course)
                ele.textContent = text
            }

            if (ele.textContent == "[chaptername] - [testname]") {
                text = text.replace(text2, data.chapterName + ' - ' + data.testName)
                ele.textContent = text
            }

            if (ele.textContent == "[taskname]") {
                text = text.replace(text2, data.course)
                ele.textContent = text
            }

            if (ele.textContent == "Term : [termname]") {
                text = text.replace(text2, 'Term : '+ data.termName)
                ele.textContent = text
            }

            if (ele.textContent == "Has successfully completed the [chaptername] in") {
                text = text.replace(text2, 'Has successfully completed the '+ data.chapterName + ' in')
                ele.textContent = text
            }

            if (ele.textContent == "Has successfully completed the Test in [chaptername] in") {
                text = text.replace(text2, 'Has successfully completed the Test in '+ data.chapterName + ' in')
                ele.textContent = text
            }

            if (ele.textContent == "Has successfully completed the Test QA in [chaptername] in") {
                text = text.replace(text2, 'Has successfully completed the Test QA in '+ data.chapterName + ' in')
                ele.textContent = text
            }

            if (ele.textContent == "Has successfully completed the test in [chaptername] in") {
                text = text.replace(text2, 'Has successfully completed the Test in '+ data.chapterName + ' in')
                ele.textContent = text
            }

            if (ele.textContent == "Has successfully completed the chapter in [chaptername] in") {
                text = text.replace(text2, 'Has successfully completed the Test in '+ data.chapterName + ' in')
                ele.textContent = text
            }
            //chapterName
        });
        Array.from(snippet.querySelectorAll('td')).forEach(function (ele) {
            var text3 = ele.textContent
            var text = ele.textContent
            const date = moment().format("DD/MM/YYYY");
            
            if (ele.textContent == "[date]") {
                text = text.replace(text3, date)
                ele.textContent = text
            }

        });
        this.certificateTemplate = this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML); 
    }

    // getCertificate(data) {
    //     let url = APP_URLS.certificateTemplate;
    //     this.service.getCallWithHeaders(url).subscribe((res: any) => {
    //         var snippet = document.createElement("div");
    //         var certificateTemplate = res.resTemp.template_text_view.replace(/\[Signature\]/g, "<img id='auth_sign' src='" + APP_URLS.FILE_PATH_LINODE + "signature/" + data.signature + "' style='width:100px;'>");
    //         snippet.innerHTML = certificateTemplate;
            
    //         Array.from(snippet.querySelectorAll('img')).forEach(function (ele) {

    //             var text = ele.src
    //             var text2 = ele.src
    //             let logo = '../../../assets/images/logo_.png'
    //             let backgroundlogo = '../../../assets/images/certificatebg.png'
    //             let val = location.origin + "/[sitelogo]"
    //             let val2 = location.origin + "/[backgroundlogo]"

    //             if (ele.src == val) {
    //                 text = text.replace(text, logo)
    //                 ele.src = text
    //             }
    //             if (ele.src == val2) {
    //                 text = text.replace(text2, backgroundlogo)
    //                 ele.src = text
    //             }
    //         });

    //         Array.from(snippet.querySelectorAll('div')).forEach(function (ele) {
    //             var text = ele.textContent
    //             var text2 = ele.textContent
    //             var text3 = ele.textContent
    //             var text4 = ele.textContent
    //             var first_name = JSON.parse(localStorage.getItem('profilesummaryData')).first_name;
    //             var middle_name = JSON.parse(localStorage.getItem('profilesummaryData')).middle_name;
    //             var last_name = JSON.parse(localStorage.getItem('profilesummaryData')).last_name;
    //             var DisplayName = first_name + ' ' + middle_name + ' ' + last_name
    //             const date = new Date()
    //             if (ele.textContent == "[studentname]") {
    //                 text = text.replace(text, DisplayName)
    //                 ele.textContent = text

    //             }
    //             if( ele.textContent == "Certified: [date]" && data.updated ){
    //                 var updateDate = new Date(data.updated).toJSON().slice(0, 10);
    //                 var dArr = updateDate.split("-");  
    //                 var result = dArr[2]+ "/" + dArr[1]+ "/" + dArr[0]; 
    //                 text = text.replace(text3, 'Certified: ' + result);
    //                 ele.textContent = text;
    //             }

    //             if( ele.textContent == "Certificate code:[certificateCode]"){
    //                 text = text.replace(text4, 'Certified code: ' + data.certificate_id);
    //                 ele.textContent = text;
    //             }

    //             if (ele.textContent == "[taskname] test") {
    //                 text = text.replace(text2, data.course)
    //                 ele.textContent = text
    //             }
    //         });
    //         Array.from(snippet.querySelectorAll('td')).forEach(function (ele) {
    //             var text3 = ele.textContent
    //             var text = ele.textContent
    //             const date = moment().format("DD/MM/YYYY");
                
    //             if (ele.textContent == "[date]") {
    //                 text = text.replace(text3, date)
    //                 ele.textContent = text
    //             }

    //         });
    //         res.resTemp.template_text_view = snippet.innerHTML
    //         this.certificateTemplate = this.sanitizer.bypassSecurityTrustHtml(res.resTemp.template_text_view);           
    //     })
    // }

    download() {
        let filename = this.data.studentname + "-" + this.data.course + '.pdf'
        var data = document.getElementById("certificate");
        
        // html2canvas(data).then(canvas => {
        //     // var imgWidth = 208;
        //     // var pageHeight = 295;
        //     // var imgHeight = canvas.height * imgWidth / canvas.width;
        //     // var heightLeft = imgHeight;
        //     var pdf = new jsPDF('a4');
        //     var imgData = canvas.toDataURL('image/jpeg', 1.0);
        //     pdf.addImage(imgData, 'JPEG', 10, 10, 180, 150); 
        //     pdf.save(filename);
        // });
        
        html2PDF(data, {
            jsPDF: {
                unit: 'px', format: 'a3', orientation: 'landscape'
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY,
                //allowTaint : true,
            },
            imageType: 'image/jpeg',
            output: filename
          });    
    }
}