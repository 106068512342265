import { Component, OnInit, OnDestroy, ViewChild, Renderer2, ElementRef, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TenantId } from '../../Models/url'
import { MyProfileService } from 'src/app/services/my-profile.service';
@Component({
  selector: 'app-individual-course',
  templateUrl: './individual-course.component.html',
  styleUrls: ['./individual-course.component.css']
})
export class IndividualCourseComponent implements OnInit, OnDestroy {

  public removeEventListener: () => void;
  courselist = {
    name: '',
    fullName: "",
    general_duration: "",
    coursefrontview: "",
    introduction: "",
    Favouritebutton: "",
    Applybutton: "",
    _id: ""
  };
   mobNumberPattern = "^[0-9]*$";
  emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  CourseEnuiry = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    "course_id": "",
    "query": "",
    "tenantId": "973159", // static
    "from_place": "course enquiry"

  }
  bannerImg: any
  coursetype = "";
  showDialogReg = false;
  courseID = "";
  @ViewChild('f') form: any;

  student_Id = '';
  openregister: boolean = false
  activeToken: boolean = false;
  CartList = [];
  FavList = [];
  course:any
  innerhtmldata: any;
  ID: string;
  Chapters = []

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private cms: CommonService,
    private sanitizer: DomSanitizer,
    private myProfileSvc:MyProfileService,
    private renderer: Renderer2, private elementRef: ElementRef) {


    this.route.params.subscribe(params => {
      this.coursetype = params['id'];
      localStorage.setItem('courseSlug',this.coursetype)
      this.getList();
    });
  }

  ngOnInit() {
    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      if (event.target instanceof HTMLAnchorElement) {
        this.handleAnchorClick(event);
      }
    });

    var userDetails = localStorage.getItem('userDetails');
    if( userDetails != undefined && typeof userDetails == 'string' ){
      var profileData = JSON.parse(userDetails);
      this.student_Id = profileData._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {
      this.activeToken = true;
    }
    else {
      this.activeToken = false
    }

    this.getData();
  }

  waitingForApproval(){
    this.service.showError("Waiting for Admin approval. Contact your Admin for further information")
  }

  getData() {
    let dataofcart = localStorage.getItem('cartdata')
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',')
    }

    let dataofFav = localStorage.getItem('favdata');
    if (dataofFav != null) {
      this.FavList = dataofFav.split(',')
    }
    this.getList();
  }

  async getList() {

    let url = APP_URLS.IndividuleCourse + '{"criteria":[{"key":"tenantId","value":"'+TenantId+'","type":"eq"},{"key":"slug","value":"'+this.coursetype+'","type":"eq"}]}'
    await this.userSvc.getCallwithHeaders(url).subscribe(
      (res: any) => {
        // if (res.all.length > 0) {
          // res.all.forEach(x => {

          let courseis = res.all[0]
          this.courselist._id = res.all[0]._id;
          this.courselist.fullName = res.all[0].fullName

            if (this.CartList.includes(courseis._id)) {
              courseis['incart'] = true
            }
            else {
              courseis['incart'] = false
            }

            if (this.FavList.includes(courseis._id)) {
              courseis['infav'] = true
            }
            else {
              courseis['infav'] = false
            }


          // })
        // }

      //   this.bannerImg = res.details.document_name

      //   if (this.activeToken) {
      //     if (res.details.favorites.length > 0) {
      //       res.details.favorites.forEach(y => {
      //         if (this.student_Id && y == this.student_Id) {

      //           res.details.Favouritebutton = "Remove Favourite"
      //         }
      //         else {
      //           res.details.Favouritebutton = "Add As Favourite"
      //         }
      //       });
      //     }
      //     else {
      //       res.details.Favouritebutton = "Add As Favourite"
      //     }
      //     if (res.details.applications.length > 0) {
      //       res.details.applications.forEach(y => {
      //         if (this.student_Id && y == this.student_Id) {
      //           res.details.Applybutton = "Applied"
      //         }
      //         else {
      //           res.details.Applybutton = "Apply"
      //         }
      //       });
      //     }
      //     else {
      //       res.details.Applybutton = "Apply"
      //     }

      //   }
      //   else {
      //     res.details.Favouritebutton = "Add As Favourite"
      //     res.details.Applybutton = "Apply"
      //   }
        // var snippet = document.createElement("div");
        // snippet.innerHTML = res.details.introduction;
        // Array.from(snippet.querySelectorAll('a')).forEach(function (ele) {
        //   var text = ele.text
        //   var Favouritebutton = res.details.Favouritebutton
        //   var text2 = ele.text
        //   var Applybutton = res.details.Applybutton
        //   if (text == "Add As Favourite") {
        //     text = text.replace(text, Favouritebutton)
        //     ele.text = text
        //   }
        //   if (text == "Apply") {
        //     text = text.replace(text2, Applybutton)
        //     ele.text = text
        //   }
        // });
        // res.details.introduction = snippet.innerHTML
        // this.courselist = res.details
        this.course = courseis;
        this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(courseis.introduction)
        this.courseID=courseis._id

      // },
      // err => {
       })
  }


  public ngOnDestroy() {
    if (this.removeEventListener) {
      this.removeEventListener();
    }
    localStorage.removeItem('coursetype');
    localStorage.removeItem('courseSlug');
  }

  public handleAnchorClick(event: Event) {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    const baseurl = anchor.baseURI
    if (anchor.href == baseurl + '#addasfav') {
      if (this.activeToken) {
        let url = APP_URLS.applyAndFavourate + this.courseID + '?favorite=true';
        this.service.putCall(url, {})
          .subscribe((data: any) => {
            this.userSvc.showSuccess(data.respMessage);
            this.getList()
          }, err => {

          })
      }
      else {
        this.service.selectedcourse(this.courselist._id, "favourite")
        this.openregister = true;
      }
    }

    if (anchor.href == baseurl + '#apply') {
      if (this.activeToken) {
        let url = APP_URLS.applyAndFavourate + this.courseID + '?applycourse=true';

        this.service.putCall(url, {})
          .subscribe((data: any) => {
            this.userSvc.showSuccess(data.respMessage);
            this.getList()
          }, err => {

          })
      }

      else {

        this.service.selectedcourse(this.courselist._id, "apply")
        this.openregister = true
      }
    }


    if (anchor.href == baseurl + '#book') {
      if (this.activeToken) {
        this.router.navigate(['/profile'])
      }
      else {
        this.openregister = true
      }

    }
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  enquiry() {
    this.CourseEnuiry.course_id = this.courselist._id;
    this.CourseEnuiry.query = this.courselist.fullName;
    let url = APP_URLS.CoureEnuiry
    this.userSvc.postCall(url, this.CourseEnuiry)
      .subscribe(
        (res: any) => {
          if (res.respCode) {
            this.userSvc.showSuccess(res.respMessage);
            this.CourseEnuiry = {
              first_name: "",
              last_name: "",
              email: "",
              mobile: "",
              course_id: "",
              query: "",
              tenantId: "",
              from_place: "course enquiry"
            }
            this.form.resetForm();
          }
          else {
            // this.cms.loaderSet(false);
            this.userSvc.showError(res.respMessage)
          }
        },

        (error) => {

          // this.cms.loaderSet(false);
          this.userSvc.showError('contact Failed')
        })



  }

addToCart(course) {

  let url = APP_URLS.addtocart + course._id + '?addtocart=true';

  if (this.student_Id) {

    this.service.putCall(url, {})
      .subscribe((data: any) => {
        if (data.suc_arr.length > 0) {
          this.userSvc.showSuccess(data.suc_arr[0]);
          this.userSvc.setCart('data')
        }
        if (data.err_arr.length > 0) {
          this.userSvc.showError(data.err_arr[0]);
        }
        this.getList()
      }, err => {

      })
  } else {
    // this.toastr.showError('Please register or login to continue');
    // this.service.selectedcourse(course._id, "addtocart")
    // this.showDialogReg=true;
    this.addtoLocalStorage(course);
  }
}

AddApply(data) {
  let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';

  if (this.student_Id) {
    this.service.putCall(url, {})
      .subscribe((data: any) => {

        this.service.showSuccess(data.respMessage);
        this.getList()
      }, err => {

      })
  } else {
    this.service.showError('Please register or login to continue');
    this.service.selectedcourse(data._id, "apply")
    this.showDialogReg = true;
  }

}

addtoLocalStorage(course) {
  this.CartList.push(course._id);
  localStorage.setItem("cartdata", this.CartList.toString())
  this.userSvc.setCart('data')
  this.userSvc.showSuccess("Course Successfully added to Your Cart");
  this.getData();
}

goToCart(){
  this.router.navigate(['/Cart'])
}

bookAppointment(){
  if(this.student_Id){

// this.service.setBookforInterview('data')
localStorage.setItem("bookinterview","true")
this.router.navigate(["/profile"])

  }
  else{
    this.myProfileSvc.setForgotPop('register')
   // this.showDialogReg = true;
  }
}

AddFav(data) {
  // let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';

  if (this.student_Id) {
    let favarry = []
    let url = APP_URLS.favmulti
    favarry.push(data._id)
    let payload = {
      courseids : favarry,
      target :"add"
    }
    this.service.putCall(url, payload)
      .subscribe((data: any) => {

        if(data.suc_arr.length > 0){
          this.service.showSuccess(data.suc_arr[0]);
        }
        if(data.err_arr.length > 0){
          this.service.showError(data.err_arr[0]);
        }
        this.getData();
      }, err => {

      })
  } else {

    // this.userservice.showError('Please register or login to continue');
    // this.service.selectedcourse(data._id, "favourite")
    // this.showDialogReg = true;
    // this.addtofavLocalStorage(data)
    this.userSvc.showError('Please register or login to continue');
  }
}


addtofavLocalStorage(course) {
  this.FavList.push(course._id);
  localStorage.setItem("favdata", this.FavList.toString())
  // this.userservice.setCart('data')
  this.service.showSuccess("Course Successfully added to Favourite List");
  this.getData();
}

removeFav(course){
  if (this.student_Id) {
    let favarry = []
    let url = APP_URLS.favmulti
    favarry.push(course._id)
    let payload = {
      courseids : favarry,
      target :"remove"
    }
    this.service.putCall(url, payload)
      .subscribe((data: any) => {
        if(data.suc_arr.length > 0){
          this.service.showSuccess(data.suc_arr[0]);
        }
        if(data.err_arr.length > 0){
          this.service.showError(data.err_arr[0]);
        }
        this.getData();
      }, err => {

      })
  } else {
  this.FavList.forEach((x, i) => {
    if (x == course._id) {
      this.FavList.splice(i, 1);
      let data = this.FavList;
      if(data.length > 0){
        localStorage.setItem('favdata', data.toString())
      }
      if(data.length == 0){
        localStorage.removeItem('favdata')
      }

      this.service.showSuccess("Removed from Favourite List");
      // this.userservice.setCart("cart")
      this.getData()

    }
  })
}
}

openCourse(Training) {
  localStorage.setItem('courseId', Training._id)
  localStorage.setItem('TrainingName', Training.name)
  localStorage.setItem('uuid', Training.resume_chapter)
  if (Training.apply_status == "assigned" && Training.resume_chapter == '') {
    this.router.navigate(['/Mycourses/', Training._id])
  }
  if (Training.apply_status == "assigned" && Training.resume_chapter != '') {

    this.ID = localStorage.getItem('courseId')
    let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
    this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        this.Chapters = res.cou_overview;
        this.setChaptersData();
      },
      err => {

      })

  }
}

FinalChaptersArray = [];

async setChaptersData() {
  await this.Chapters.forEach((x, i) => {
    x['accordion'] = '#accordion' + i
    x['accordionID'] = 'accordion' + i
    x.child.forEach((y, index) => {
      y['childaccordion'] = '#childaccordion' + index
      y['childaccordionID'] = 'childaccordion' + index
      if (y.chapter_type == "lesson") {
        y['icon'] = "fa fa-book"
      }
      if (y.chapter_type == "assignment") {
        y['icon'] = "fa fa-code"
      }
      if (y.chapter_type == "downloads") {
        y['icon'] = "fa fa-cloud-download-alt"
      }
      if (y.chapter_type == "test" || y.chapter_type == "qatest") {
        y['icon'] = "fa fa-pencil-alt"
      }
      if (y.chapter_type == "quiz") {
        y['icon'] = "fa fa-graduation-cap"
      }
      if (y.chapter_type == "chapter") {
        y['icon'] = "fa fa-bars"
      }
    })
    this.FinalChaptersArray.push(x)
    localStorage.setItem('AllChapters', JSON.stringify(this.FinalChaptersArray))
    var Trainingid = localStorage.getItem('courseId')
    var TrainingName = localStorage.getItem('TrainingName')
    var resume_chapter = localStorage.getItem('uuid')
    this.router.navigate(['/Mychapter/', Trainingid + "-" + resume_chapter, TrainingName.replace(/\s/g, '-')])
  })
}


}