import { Component, OnInit, OnDestroy, Input, Output, OnChanges, EventEmitter, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import 'magnific-popup';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  showDialogReg = false;
  subscribe={
    email:""
  }
  coursetype: string;
  isOpenVideoDialog : boolean = false;
  student_Id = '';
  activeToken: boolean = false;
  userRole:any;

  slides = [{ id: 342, name : 'Ellison Johnson', img : './assets/next-global/images/content/students/1.jpeg', occupation : 'Assistant professor', desc : "I always thought that people used to pay much for quality. But these guys changed my opinion. The quality exceeds the price many times. I recommend it to everybody. I recommend it to everybody." },
  { id: 453, name : 'Maria D.', img : './assets/next-global/images/content/students/2.jpeg', occupation : 'Psychology Professor', desc : "I always thought that people used to pay much for quality. But these guys changed my opinion. The quality exceeds the price many times. I recommend it to everybody. I recommend it to everybody."},
  { id: 846, name : 'Mellisa Doe', img : './assets/next-global/images/content/students/3.jpeg', occupation : 'Economic Professor', desc : "I always thought that people used to pay much for quality. But these guys changed my opinion. The quality exceeds the price many times. I recommend it to everybody. I recommend it to everybody." }];

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false
  };
  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }
  
  @ViewChild('video1') videoElement1:ElementRef; 
  @ViewChild('video2') videoElement2:ElementRef; 
  ngAfterViewInit(): void {
   if (this.videoElement1) {
       $(this.videoElement1.nativeElement).magnificPopup({ type: 'iframe' });
    }
    if (this.videoElement2) {
      $(this.videoElement2.nativeElement).magnificPopup({ type: 'iframe' });
   }
  }
  constructor(private router: Router,
    private profileSvc:MyProfileService,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {

    this.userRole = localStorage.getItem('role');

    var userDetails = localStorage.getItem('userDetails');
    if( userDetails != undefined && typeof userDetails == 'string' ){
      var profileData = JSON.parse(userDetails);
      this.student_Id = profileData._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {
      this.activeToken = true
    } else {
      this.activeToken = false
    }

    this.coursetype=localStorage.getItem('courseSlug')
    if(this.coursetype){
      this.router.navigate(['/'+this.coursetype])
    }else{
      this.router.navigate(['/home'])
    }
  }

  registerOpen(){
    this.profileSvc.setForgotPop('register')
  }
  loadVideo(){
    this.isOpenVideoDialog = true;
  }
  submit(){
    let payload={
      email: this.subscribe.email,
    first_name: this.subscribe.email.split('@')[0],
    last_name: this.subscribe.email.split('@')[0],
    tenantId: TenantId, 
    from_place: 'newsletter'

    }
    let url = APP_URLS.newlatter;
    
    this.userSvc.postCall(url,payload)
    .subscribe((data:any)=>{
      this.subscribe.email=""
      this.userSvc.showSuccess(data.respMessage)
    })  
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  profile() {
    if( this.userRole == 'employer'){
      this.router.navigate(['/employer-profile/emp-profile-summary'])
    } else {
      this.router.navigate(['/profile'])
    }
  }
}
