import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service'
import { ToastrService } from 'ngx-toastr';

import { TenantId } from '../../Models/url';
import { TrainingService } from 'src/app/services/training.service';
@Component({
  selector: 'app-mycourses',
  templateUrl: './mycourses.component.html',
  styleUrls: ['./mycourses.component.css']
})
export class MycoursesComponent implements OnInit {

  showDialogStandard: boolean = false;
  showDialogStandardFeedback: boolean = false;
  ID: any;
  p= 1;
  studentId: any;
  courseId: any;
  progressionList = []
  FinalChaptersArray = [];
  testCourse: string
  portfolioDetails: [];
  sampleEvidence: boolean = false;
  sampleSubmit: boolean = false;
  selected: {};
  selectSubmit: {};
  Chapters: any;
  TrainingName: any;

  AllChapters = [];
  chaptersData = [];
  assignment_report = [];
  AllOffthejobs = [];
  onlineLearningCount = 0;
  MentoringCount = 0;
  CentreCount = 0;
  houseCPDCount = 0;
  totalHours = 0
  playData = [];
  showCard: boolean = false
  TestArray = [];
  QATestArray = [];
  QuizArray = [];
  Standards = [];
  Feedback: any;
  aboutlength: any;
  //portfoliolenth: any;
  button = "Expand All"
  show: boolean = false
  uuid: string;
  feedbackpopupopen = 'none';

  rateoptions = [];
  queries = []
  courserating: number[] = [1, 2, 3, 4, 5];
  staffrating: number[] = [1, 2, 3, 4, 5];
  feedback_0: number[] = [1, 2, 3, 4, 5];
  queriesresponse = {
    aboutcourse: "",
    aboutstaff: "",
    courserating: "",
    staffrating: "",
  }
  feedbackSubmit = "no"
  isfeedbackDetail = [];
  feedback = {
    id: "",
    parent: "",
    chapterId: ""
  }
  countPagination=10
  opentype: any;

  certificate_courseComplete_Status = 'Pending';
  courseFinishDetailRes:any;
  chapter_uuid_status:any;
  finish = 0;
  not_finish = 0;
  finishButtonAllow = false;

  opentypehref: string;
  totalResponce: any;
  examdata: any;
  parentAccordion: any;

  enablePortFolio : boolean = false;
  enableCourseDwnld : boolean = false;
  enableOfftheJob : boolean = false;
  enableAbtCourse : boolean = false;
  enablePlay : boolean = false;
  enableTest : boolean = false;
  enableQATest : boolean = false;
  enableQuiz : boolean = false;

  isNoChapters : boolean = false;
  notfinish = 0;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: MyProfileService,
    private userService: UserService,
    private toast: UserService,
    private cms: CommonService,
    private profservice : TrainingService,
    private toaster: ToastrService,
  ) {}

  changePage(e){
    if(!e){
      e=1;
    }
    this.p=e;
  }

  ngOnInit(): void {
    // if (!localStorage.getItem('foo')) { 
    //   localStorage.setItem('foo', 'no reload') 
    //   location.reload() 
    // } else {
    //   localStorage.removeItem('foo') 
    // }
    this.ID = localStorage.getItem('courseId')
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.uuid = localStorage.getItem('uuid')
    this.courseId = localStorage.getItem('courseId')
   
    this.TrainingName = localStorage.getItem('TrainingFullName') || localStorage.getItem('TrainingName')
    this.getData();
    
    this.getProgressionData();
    this.getDataDownload();

    this.getPortfolio();
    this.getOfftheJobs();
    this.aboutCourse();
    this.getPlayList();
    this.courseFinishDetail();
  }
  async courseFinishDetail(){
    this.chapter_uuid_status = {};
    this.finish = 0;
    this.not_finish = 0;
    var course = this.ID;
    let url = APP_URLS.v1.courseFinishDetail;
    url += '?filter={"criteria":[{"key":"course","value":"'+course+'","type":"eq"}]}';
    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      if(typeof res.chapter_uuid_status != 'undefined'){
        this.courseFinishDetailRes = res; 
        this.chapter_uuid_status = res.chapter_uuid_status;
        this.finish = res.chapter_uuid_status.finish;
        this.not_finish = res.chapter_uuid_status.not_finish;

        if(typeof res.certificate_courseComplete!='undefined'){
          this.certificate_courseComplete_Status = res.certificate_courseComplete.status;
        } else {
          this.certificate_courseComplete_Status = 'Pending';
        }

        if(this.not_finish==0){
          this.finishButtonAllow = true;
        }
      }
    });
  }
  async requestCertificate(){
    let url = APP_URLS.oldApi.CertificateRequest+this.ID+'?target=request-course-completion-certificate';
    let Payload = {
      certificate_request: true
    };
    await this.userService.putCallwithHeaders(url, Payload).subscribe((res: any) => {
      if(typeof res.toast != 'undefined'){
        if(res.toast.type == 'success'){
          this.userService.showSuccess(res.toast.message);
          this.courseFinishDetail();
          // this.router.navigate(['/Mycourses/', this.ID])
        }
      }
      if(typeof res.errorMessage != 'undefined'){
        this.userService.showError(res.errorMessage);
      }
    });
  }

  requestedCertificate(){
    this.toaster.warning("Your request for the certificate is already sent and waiting for the approval from the course department. For further information, you may contact your admin")
  }

  courseCompleted(){
    this.userService.showSuccess('You have now completed this courses successfully. you may request for the course completion certificate by clicking on "Request for Couse Certificate" button');
  }

  acceptedCertificate(){
    this.userService.showSuccess('Goto your My profile - Certificate tab, you can see your certificate in Course Certificate');
  }

  feedbackPopupOpen(data) {
    this.queries = []
    this.rateoptions = []
    this.isfeedbackDetail = []
    this.queriesresponse.aboutcourse = ""
    this.queriesresponse.aboutstaff = ""
    this.queriesresponse.courserating = ""
    this.queriesresponse.staffrating = ""
    this.feedbackpopupopen = 'block';
    if (data) {
      data.isfeedback_det.forEach(x => {
        this.rateoptions.push(x.rateoptions)
        this.queries.push(x.queries)
        this.isfeedbackDetail.push(x.isfeedbackDetail)
      });
      if (data) {
        if (data.isfeedback_det[0]) {
          this.feedbackSubmit = data.isfeedback_det[0].isfeedbackSubmit
        }
      }

      this.feedback.chapterId = data._id
      this.feedback.id = data.isfeedback_det[0]._id
      this.feedback.parent = data.parent
    }
    if (this.isfeedbackDetail.length > 0) {
      this.isfeedbackDetail[0].forEach((x) => {
        if (x.queriesresponse.length > 0) {
          x.queriesresponse.forEach((y, index) => {
            this.queriesresponse.aboutcourse = y.aboutcourse
            this.queriesresponse.aboutstaff = y.aboutstaff
            this.queriesresponse.courserating = y.courserating
            this.queriesresponse.staffrating = y.staffrating
            if (this.queries[0].length > 0) {
              this.queries[0].forEach((z, i) => {
                let value = "feedback_" + i
                Object.keys(y).filter(k => {
                  if (k == value) {
                    z['feedback'] = y[k]
                  }
                }
                );
              })
            }

          })
        }
      });

    }
    else {
      this.queriesresponse.aboutcourse = ""
      this.queriesresponse.aboutstaff = ""
      this.queriesresponse.courserating = ""
      this.queriesresponse.staffrating = ""
      if (this.queries.length > 0) {
        this.queries[0].forEach((x, i) => {
          x['feedback'] = ""

        })

      }
    }
   
  }
  feedbackPopupClose() {
    this.feedbackpopupopen = 'none'
  }
  feedBackStar(star, index) {
    this.queriesresponse["feedback_" + index] = star;
    if (this.queries.length > 0) {
      this.queries[0].forEach((x, i) => {
        if (index == i) {
          x['feedback'] = star
        }
      })
    }

  }

  staffStar(star) {
    this.queriesresponse.staffrating = star;

  }
  courseStar(star) {
    this.queriesresponse.courserating = star;

  }
  submitfeedback() {

    var payload = {
      student_id: this.studentId,
      feedback_id: this.feedback.id,
      assigned_courses: this.ID,
      assigned_chapter: this.feedback.chapterId,
      parentuuid: this.feedback.parent,
      queriesresponse: this.queriesresponse
    }
    let url = APP_URLS.submitFeedbackModel
    this.service.postCallwithHeaders(url, payload)
      .subscribe((res: any) => {
        if (res.respCode == 201) {
          this.toast.showSuccess(res.respMessage);
          this.feedbackPopupClose()
          this.getData();
        }
        else {
          this.toast.showError(res.respMessage)
        }
      },
      err => {
        this.toast.showError('Failed')
      })
  }


  toggle() {
    this.show = !this.show
    if (this.show == false) {
      this.button = "Expand All"
    }
    else {
      this.button = "Collapse All"
    }

  }
  selectedStandard(standards) {
    this.Standards = standards
    this.showDialogStandard = !this.showDialogStandard
  }

  async getData() {
    

    let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
    await this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        this.totalResponce = res;
        this.finish = res.chapter_uuid_status.finish;
        this.notfinish = res.chapter_uuid_status.not_finish;
        
        this.Chapters = res.cou_overview;
        this.setChaptersData();
        this.getTestData()       
      },
      err => {
      })
  }
 async setChaptersData() {
  this.FinalChaptersArray = [];
  await this.Chapters.forEach((x, i) => {
      x['accordion'] = '#accordion' + i
      x['accordionID'] = 'accordion' + i
      x['iscompleted'] = ( x.child.length ) ? "yes" : "no";
      x.child.forEach((y, index) => {
        y['childaccordion'] = '#childaccordion' + index
        y['childaccordionID'] = 'childaccordion' + index
        if (y.chapter_type == "lesson") {
          y['icon'] = "fa fa-book"
        }
        if (y.chapter_type == "assignment") {
          y['icon'] = "fa fa-code"
        }
        if (y.chapter_type == "downloads") {
          y['icon'] = "fa fa-cloud-download-alt"
        }
        if (y.chapter_type == "test" || y.chapter_type == "qatest") {
          y['icon'] = "fa fa-pencil-alt"
        }
        if (y.chapter_type == "quiz") {
          y['icon'] = "fa fa-graduation-cap"
        }
        if (y.chapter_type == "chapter") {
          y['icon'] = "fa fa-bars"
        }
        if(y.iscompleted == "no"){
          x.iscompleted = "no"
        }
        
      })
      this.FinalChaptersArray.push(x)
    })    
    this.isNoChapters = ( this.FinalChaptersArray.length ) ? false : true ;
  }



  async getProgressionData() {
    
    let url = APP_URLS.myCourseProgression + '[{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"},{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}';
    await this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
       
        res.assignments.forEach(x => {
          if (x.exam_type != "general_portfolio") {
            this.progressionList.push(x)
          }
        });

        if( this.progressionList.length > 0 ){

          this.progressionList.forEach(y => {
            y.approveUIFlag = false;
            y.notApproveUIFlag = false;
            y.underReviewUIFlag = false;
            if( y.review_status || y.review_iv_status || y.review_three_status ){
              if( y.review_status == 'A' || y.review_iv_status == 'A' || y.review_three_status == 'A'){
                y.approveUIFlag = true;
                y.notApproveUIFlag = false;
                y.underReviewUIFlag = false;
              } else if( y.review_status == 'NA' || y.review_iv_status == 'NA' || y.review_three_status == 'NA'){
                y.approveUIFlag = false;
                y.notApproveUIFlag = true;
                y.underReviewUIFlag = false;
              } else if( y.review_status == 'UR' || y.review_iv_status == 'UR' || y.review_three_status == 'UR'){
                y.approveUIFlag = false;
                y.notApproveUIFlag = false;
                y.underReviewUIFlag = true;
              }
            }
          });

        }
      },
      err => {
      })
  }


  getsegragateData() {

    this.Chapters.forEach((x, i) => {

      let segragateData = {
        parent: {},
        childArray: []
      }
      if (x.parent == "") {
        x['accordion'] = '#accordion' + i
        x['accordionID'] = 'accordion' + i
        segragateData.parent = x;
        this.Chapters.forEach((y, index) => {
          let childdata = {
            subchapter: {},
            subChild: []
          }
          if (x.uuid == y.parent) {
            y['childaccordion'] = '#childaccordion' + index
            y['childaccordionID'] = 'childaccordion' + index

            childdata.subchapter = y

          

            this.Chapters.forEach(z => {
              if (y.uuid == z.parent) {
                childdata.subChild.push(z
                  )
              }
            })

           

            segragateData.childArray.push(childdata)

          }

        })
        this.FinalChaptersArray.push(segragateData)
      }

    })



  }
  resumeChapter() {
    localStorage.setItem('AllChapters', JSON.stringify(this.FinalChaptersArray))
    var name = localStorage.getItem('TrainingName')
    if (this.uuid) {
      this.router.navigate(['/Mychapter/', this.ID + "-" + this.uuid, name.replace(/\s/g, '-')])
    }
  }

  selecteChapter(data,parent, parentIndex, childIndex) {
    var allow : boolean = false;

    var errMessage = 'You must complete the previous section and get Approval';
    if( data.iscompleted == 'yes' ){
      allow = true;
    } else if( childIndex == 0 ){
      allow = true;
    } else if( (( parseInt(childIndex) - 1 )  > -1 )){
      if( this.FinalChaptersArray[parentIndex]['child'][parseInt(childIndex) - 1]['iscompleted'] == 'yes'){
        allow = true;
      }
    }

    if( allow && data.type == 'assignment'){
      if( data.termWiseShowHide && data.termWiseShowHide == 'hide'){
        allow = false;
        errMessage = 'This chapter not available now, contact your teacher';
      }
    }

    if( allow ){
      localStorage.setItem('AllChapters', JSON.stringify(this.FinalChaptersArray))
      localStorage.setItem('uuid', data.uuid)
      localStorage.setItem('coursename', data.name)
      localStorage.setItem('parentID', parent._id)
      localStorage.setItem("courseheading",parent.name)
      localStorage.setItem('chapterID', data.chapter_id)
      this.router.navigate(['/Mychapter/', this.ID + "-" + data.uuid, data.name.replace(/\s/g, '-')])
    } else {
      this.userService.showError(errMessage);
    }
  }


  async getPortfolio() {
    let url = APP_URLS.portfolio_of_evidence + '[{"key":"courses_id","value":["' + this.ID + '"],"type":"in"},{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}'
    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      this.portfolioDetails = res.generalportfolio_list;
      this.enablePortFolio = ( this.portfolioDetails.length ) ? true : false;
      this.getAssignments();
    })
  }

  getAssignments() {
    let url = APP_URLS.assignments + '[{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"},{"key":"tenantId","value":"917833","type":"eq"}]}'
    this.service.getCallwithHeader(url)
      .subscribe((data: any) => {

        this.assignment_report = data.assignments;


      

      })
  }

  
  getDataDownload() {
    
    let url = APP_URLS.chapters + '{"sortfield":"sort_position","direction":"asc","criteria":[{"key":"course","value":"' + this.ID + '","type":"eq"},{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}';


    this.userSvc.getCallWithHeaders(url)
      .subscribe((res: any) => {

        this.AllChapters = res.chapters;
        this.chaptersData = []
        this.enableCourseDwnld = (this.chaptersData.length ) ? true : false;
     
      })

  }


  async getOfftheJobs() {
    let url = APP_URLS.getOffTheJobs + this.courseId + '/' + this.studentId;
   await this.service.getCallwithHeader(url)
      .subscribe((data: any) => {

        this.AllOffthejobs = data.offthejob;
        this.enableOfftheJob = ( this.AllOffthejobs.length ) ? true : false;

        this.AllOffthejobs.forEach(x => {
          if (x.category == 'In-house CPD') {
            this.houseCPDCount = this.houseCPDCount + 1;
          }
          if (x.category == 'Attendance at College/Centre') {
            this.CentreCount = this.CentreCount + 1;
          }
          if (x.category == 'Online Learning') {
            this.onlineLearningCount = this.onlineLearningCount + 1;
          }
          if (x.category == 'Mentoring') {
            this.MentoringCount = this.MentoringCount + 1;
          }
          if (x.hours) {
            this.totalHours += parseInt(x.hours);
          }

        });


      }, err => {


      })
  }
 
  async aboutCourse() {
 
    let url = APP_URLS.aboutCourse + '[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"_id","value":"' + this.ID + '","type":"eq"}]}';

   await this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        this.testCourse = res.courses[0].aboutcourse;
        // this.aboutlength = this.testCourse
        this.enableAbtCourse = ( this.testCourse != '' ) ? true : false;
      },
      err => {
        
      })
  }

 
  async getPlayList() {
    let url = APP_URLS.getPlay + this.ID + '?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}'

    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      this.playData = res.playcourses;
      this.enablePlay = ( this.playData ) ? true : false;

      this.playData.forEach(x => {
        x.completed_students.forEach(y => {
          if (y.toString() == this.studentId.toString()) {
            this.showCard = false
          }
          else {
            this.showCard = true
          }
        });
      })


    })
  }

 
   getTestData() {
  
    if(this.totalResponce && this.totalResponce.independent && this.totalResponce.independent.length > 0){
    this.totalResponce.independent[0].child.forEach(x => {
      this.TestArray = x.test;
      this.enableTest  = ( this.TestArray.length ) ? true : false;
      this.QATestArray = x.qatest;
      this.enableQATest = ( this.QATestArray.length ) ? true :  false;
      this.QuizArray = x.quiz;
      this.enableQuiz = ( this.QuizArray.length ) ? true :  false;
    })
  }
  }

  openTest(data) {
    localStorage.setItem('uuid', data.uuid)
    localStorage.setItem('chapterID', data.chapter_id)
    this.router.navigate(['/Mychapter/', this.ID + "-" + data.uuid, data.name.replace(/\s/g, '-')])
  }

  opentesttype = ""
  openqatesttype = ""
  openquiztype = ""

  openExam(type) {
    if(this.notfinish == 0){
    if (type == 'test') {
      this.opentesttype = 'test'
     
    }
    if (type == 'qatest') {
      this.openqatesttype = 'qatest'
     
    }
    if (type == 'quiz') {
      this.openquiztype = 'quiz'
     
    }
  }
     else{
  this.toast.showError("Please complete all the Chapters")
     }




  }

}
