import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MyProfileService } from '../../services/my-profile.service';

@Component({
  selector: 'app-modaldialog',
  templateUrl: './modaldialog.component.html',
  styleUrls: ['./modaldialog.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class ModaldialogComponent implements OnInit {
@Input() data : any
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() dialogSize = 'medium';
  enableMaxDialog : boolean = false;

  constructor(public service: MyProfileService,) { }

  ngOnInit() { 
    this.enableMaxDialog = false;
    if( this.dialogSize ){
      this.enableMaxDialog = ( this.dialogSize == 'max' ) ? true : false;
    }

    this.service.getPopup().subscribe(data => {
      this.visible = data;
    })
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.service.clearModelPopup(true)
    if(this.data == 'editFalg'){
      this.service.seteditprofile("dummy")
    }
  }

}
