// let baseUrl = 'http://localhost:3000/api/';
//  let baseUrl = 'http://172.104.186.153:3000/api/'
// AB Academy
let baseUrl = '/api/'
let attachment = 'attachments'

//  let baseUrl = 'https://app.besttrainings.co.uk/api/'
export const TenantId = 973159;
export const tenantConfig =
    {
        "tenantId": 973159,
        "url": "http://nextstepglobalcollege.com/",
        "email": "admissions@destinyoverseas.com",
        "company": "ELearning tool",
        "domain": "Nextstepglobalcollege",
        "logo": "1600954793155_nextstep-globalcollege.png"
    }


  export const tenantInfo = {
    "siteUrl": "nextstepglobalcollege.com",
    "name" : "Next Step Global College",
    "nameUpperCase" : "NEXT STEP GLOBAL COLLEGE",
    "nameShort" : "NSG College",
    "email": "info@nsgcollege.com",
    "logoUrl": "../../../assets/next-global/images/nextstep-globalcollege_.png",
    "contact_email":"contact@nextstepglobalcollege.co.uk"
  };


  //  export const TenantId = 917833
// let domain = "Best-Training-London";

// ADMi
// let baseUrl = 'https://app.theadmi.com/'
// export const TenantId = 577224
export const APP_URLS = {

  FILE_PATH_LINODE:`https://daipp-data-buckets.eu-central-1.linodeobjects.com/${TenantId}/`,

  Login: baseUrl + 'students/signin',
  Courses: baseUrl + 'courses?filter={"criteria":[{"key":"tenantId","value":' + `"` + TenantId + `"` + ',"type":"eq"}]}&tenantId=' + TenantId,
  Register: baseUrl + 'students',
  CoureEnuiry: baseUrl + 'students',
  FavourateTraining: baseUrl + 'courses?favorite=true&filter={"criteria":[{"key":"tenantId","value":' + `"` + TenantId + `"` + ',"type":"eq"}]}',

  CourscesList: baseUrl + 'vone/course/student?filter=',
  AppliedTraining: baseUrl + 'courseapplications?context=',
  AllCertificates: baseUrl + 'vone/coursetest/student',
  MyCertificates: baseUrl + 'students/5ea952ed64f7a81652671320?filter={"criteria":[{"key":"tenantId","value":' + `"` + TenantId + `"` + '","type":"eq"}]}',
  // IndividuleCourse  : baseUrl + 'coursesslug/digital-marketer?',
  chapters: baseUrl + 'chapters?forbend=viewmycourse&filter=',
  newchapters: baseUrl + 'vone/lmsmain?filter=',
  IndividuleCourse: baseUrl + 'vone/course/student?filter=',
  profileDetails: baseUrl + 'students/',
  ProfileDropdown: baseUrl + 'groupedlist',
  fileUpload: baseUrl + 'attachments?from=student',
  assignmentUpload: baseUrl + 'attachments?from=chapter',
  addQualifications: baseUrl + 'events?context=',
  Progression: baseUrl + 'assignmentsBystudent/',
  changePassword: baseUrl + 'students/changePassword',
  getQualificationandEmployementandPersonl: baseUrl + 'events?filter={"criteria":[{"key":"tenantId","value":' + `"` + TenantId + `"` + ',"type":"eq"},{"key":"event_type","value":"REMINDER","type":"orcon"},{"key":"event_type","value":"ATTACHMENT","type":"orcon"},',
  supportTicket: baseUrl + 'inboxes?filter={"limit":1000,"page":1,"sortfield":"created","direction":"asc","criteria":',



  feedback: baseUrl + 'getbystudentfeedback/',

  getstudentfeefinance: baseUrl + 'getstudentfeefinance/',
  student_addOrUpdatestudentfeefinance: baseUrl + 'student_addOrUpdatestudentfeefinance/',

  students_getChecklistDeclarations: baseUrl + 'students_getChecklistDeclarations/',
  student_addOrUpdateChecklistDeclarations: baseUrl + 'student_addOrUpdateChecklistDeclarations/',
  bookForInterview: baseUrl + 'vone/profile/bookforinterview',
  getCertificate : baseUrl + 'vone/getcertificate',

  myCourseProgression: baseUrl + 'assignments?filter={"sortfield":"updated","direction":"desc","criteria":',
  aboutCourse: baseUrl + 'courses?filter={"criteria":',


  getbookforinterview: baseUrl + 'events?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"event_type","value":"REMINDER","type":"orcon"},{"key":"event_type","value":"ATTACHMENT","type":"orcon"},',
  getOffTheJobs: baseUrl + 'offthejob/',
  postOffTheJobs: baseUrl + 'studenttimesheets',
  portfolio_of_evidence: baseUrl + 'generalportfolio/getlist?filter={"criteria":',
  assignments: baseUrl + 'assignments?filter={"sortfield":"updated","direction":"desc","criteria":',
  getPlay: baseUrl + 'playcourseslist/',
  killPlay: baseUrl + 'updatestudents/',
  rePlay: baseUrl + 'removestudents/',
  studentAppliedTest: baseUrl + 'chapterstest?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}',
  AssignmentsApi: baseUrl + 'assignments',
  // fileUpload: baseUrl + 'attachments?from=student',
  evidence_fe: baseUrl + 'attachments?from=evidence_fe',


  contactus: baseUrl + 'students',
  selectCertificate: baseUrl + 'coursetests/',
  applyTest: baseUrl + 'coursetestsapply/',
  applyAndFavourate: baseUrl + 'courses/',
  studentsubmit: baseUrl + 'vone/chapterstest/studentsubmit',
  forGotPassword: baseUrl + 'students/forgotPassword?email=',
  resetPassword: baseUrl + 'students/changeRecoverPassword/',

  // resetPassword:baseUrl+'students/changeRecoverPassword/',
  notification: baseUrl + 'notifications/get?fr=ur&filter={"sortfield":"created","direction":"desc","criteria":',
  updatedFeedback: baseUrl + 'updatestudentfeedback/',
  feedbackDetails: baseUrl + 'feedbackbyid/',
  // updatedFeedback:baseUrl+'updatestudentfeedback',
  supportTicketUpload: baseUrl + 'attachments?from=download',
  supportTicketSubmit: baseUrl + 'inboxes',

  getResult: baseUrl + 'vone/chapterstest/getrecord?filter=',
  newlatter: baseUrl + 'students',
  chaptersteststudent: baseUrl + 'chaptersteststudent/',
  coursetest: baseUrl + 'vone/coursetest/getrecord?',
  lmsInner: baseUrl + 'vone/lmsinner?uuid=',
  lmsInnerParent: baseUrl + 'vone/lmsinner?parent=',
  // lmsInnerSubmit:baseUrl+'assignments',
  lmsInnerSubmit: baseUrl + 'vone/course/studentsubmit',
  resumeChapter: baseUrl + 'courseapplications/',
  // imagePath: 'https://app.besttrainings.co.uk/',
  imagePath: `https://daipp-data-buckets.eu-central-1.linodeobjects.com/${TenantId}/`,
  allTrainings: baseUrl + 'vone/course/student',
  ProfileCertificates: baseUrl + 'vone/certificate/student',
  Categories: baseUrl + 'vone/course/category?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}',
  profilePermissions: baseUrl + 'vone/profile/permission',

  DoSignature: baseUrl + 'DoSignature',
  getApprenticeshipAgreementChecklist: baseUrl + 'students_getApprenticeshipAgreementChecklist',
  getCommitmentStatement: baseUrl + 'students_getCommitmentStatement',
  getMilestone: baseUrl + 'students_getMilestone',
  getRolesAndResponcebilities: baseUrl + 'students_getRolesAndResponcebilities',
  getRecommendedDuration: baseUrl + 'students_getRecommendedDuration',
  getTotalNegotiatedPrice: baseUrl + 'students_getTotalNegotiatedPrice',
  getEvidenceSupportAPL: baseUrl + 'students_getEvidenceSupportAPL',
  getEmployerAgreement: baseUrl +'students_getEmployerAgreement',
  UpdateEmployerAgreement:baseUrl+'students_UpdateEmployerAgreement',
  getStandardWeeklyWorkHours:baseUrl+'students_getStandardWeeklyWorkHours',
  Lmsassignments : baseUrl + 'assignments/',
  CertificateRequest : baseUrl + 'certificaterequest/',
  notificationClick:baseUrl+'notifications/click',
  certificateTemplate:baseUrl+'vone/certificate/template',
  dinamicimg : baseUrl,
  ticketReply: baseUrl+'inboxes/',
  submitFeedbackModel:baseUrl+'studentsfeedback',
  tickentClose:baseUrl+'inboxes/',

  lmsInnersubchapter: baseUrl + 'vone/lmsinner?parent=',

  getIndividualLearningAgreement: baseUrl + "students_getIndividualLearningAgreement",
  getEmpOrganisation: baseUrl + 'students_getEmpOrganisation',
  getAssessorTrainingProvider: baseUrl + 'students_getAssessorTrainingProvider',
  getVerificationofIdentitIDDocuments: baseUrl + 'students_getVerificationofIdentitIDDocuments',
  getequalification: baseUrl + 'students_getequalification',
  getQualificationCheckedLARA: baseUrl + 'students_getQualificationCheckedLARA',
  getmanageEmpInfo: baseUrl + 'students_getmanageEmpInfo',
  getModeOFdeliveryLocation: baseUrl + 'students_getModeOFdeliveryLocation',
  getPlannedLearningHours: baseUrl + 'students_getPlannedLearningHours',
  getEqualityAndDiversity: baseUrl + 'students_getEqualityAndDiversity',
  getPreEntryInformation: baseUrl + 'students_getPreEntryInformation',
  getAllConsents: baseUrl + 'students_getAllConsents',
  getAdditionalSupportNeedsQuestions: baseUrl + 'students_getAdditionalSupportNeedsQuestions',
  getPlannedReviewDates: baseUrl + 'students_getPlannedReviewDates',
  getPersonalInformation: baseUrl + 'students_getPersonalInformation',
  getChangeLog: baseUrl + 'students_getChangeLog',
  getSkillCoach: baseUrl + 'students_getSkillCoach',
  getJournalEntry: baseUrl + 'students_getJournalEntry',
  getProgressReview: baseUrl + 'students_getProgressReview',
  getcourseidentification: baseUrl + 'students_getcourseidentification',
  getBasicAssessmentRpl: baseUrl + 'students_getBasicAssessmentRpl',
  getInductionLearningcentre:baseUrl+'students_getInductionLearningcentre',
  getWorkPlacementHealthAndSafetyAssessment:baseUrl+'students_getWorkPlacementHealthAndSafetyAssessment',
  getIndividualLearningPlanandSignatures:baseUrl+'students_getIndividualLearningPlanandSignatures',
  getStudentDetails:baseUrl+'students/',
  profileFeedback:baseUrl+'vone/profile/feedback',
  bookForInterviewCalenderData : baseUrl+'vone/profile/bookforinterview/calender',
  bookForInterviewslotCreation:baseUrl+'slotCreation',
  bookForInterviewslotAllocation :baseUrl+'slotAllocation',
  getconditionalofferletter:baseUrl+'students_getconditionalofferletter',
  getapplicationprocess:baseUrl+'students_getapplicationprocess',

  addtocart : baseUrl + 'vone/course/cart/',
  getcart : baseUrl + 'vone/course/cart',
  removefromcart : baseUrl + 'vone/course/cart/',
  cartmulti : baseUrl + 'vone/course/cartmulti?addtocart=true',

  latestcourse : baseUrl + 'vone/course/student?tenantId='+TenantId,

  updateManaeAddress:baseUrl+'vone/profile/updateAddress',
  getManageAddress:baseUrl+'vone/profile/details',
  applicationspayment : baseUrl+'vone/course/applicationspayment',

  //fav course
  favmulti : baseUrl+'vone/course/favmulti',
  attachmentUrl : baseUrl + 'attachments',
  tenantSettings : baseUrl+'tenantsettings',
  v1 : {
    courseFinishDetail:baseUrl+'v1/course/finishdetail',
    getAllCertificateTemplates : baseUrl+'v1/certificate/template ',
    getCourseDetails : baseUrl + 'v1/courseapplication/bycourseandstudent',
    getEncodedUrl: baseUrl + 'v1/encodeimgurl',
    getEmployeeList: baseUrl + 'v1/student/employeelist',
    getHelpList : baseUrl + 'v1/help/list',
    bookForInterviewslotCreation : baseUrl + 'v1/slotCreation',
    login: baseUrl + 'v1/user/signin',
    userDetails : baseUrl + 'v1/user/details',
    settingsData : baseUrl + 'v1/settingsdata',
    changePassword : baseUrl + 'v1/user/changePassword',
    myProjects : baseUrl + 'v1/mywork/projects/list',
    myTask : baseUrl + 'v1/mywork/task/list/byemployer',
    supportTicket : baseUrl + 'v1/user/ticket/support',
    getEmployerAgreement : baseUrl + 'v1/user/agreement/list',
    createSupportTicket : baseUrl + 'v1/user/ticket/support/create',
    replySupportTicket : baseUrl + 'v1/user/ticket/support/reply',
    getMyApprentice : baseUrl + 'v1/report/user',
    myTaskStudent: baseUrl + 'v1/mywork/task/list',
    kyc: baseUrl + 'v1/mywork/kyc/data',
    kycSubmit: baseUrl + 'v1/mywork/kyc/submit',
    withdraw: baseUrl + 'v1/mywork/withdraw/list',
    requestWithdraw: baseUrl + 'v1/mywork/withdraw/request',
    channelList: baseUrl + 'v1/mywork/channel/list',
    channelById: baseUrl + 'v1/mywork/channelaccount/list',
    channelCreate: baseUrl + 'v1/mywork/channelaccount/create',
    wallet: baseUrl + 'v1/mywork/wallet',
    takeTask : baseUrl + 'v1/mywork/task/take/request',
    taskSubmit: baseUrl  + 'v1/mywork/task/submit',
    profilePictureUpdate:baseUrl+'v1/user/profile_picture',
  },
  oldApi : {
    CertificateRequest : baseUrl + 'certificaterequest/',
  }
}
