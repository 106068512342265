import { Component, OnInit, Input, ViewChild, HostListener, APP_ID, ElementRef } from '@angular/core';
import { APP_URLS, tenantInfo } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { TenantId } from '../../Models/url';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import * as jspdf from 'jspdf';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { HttpClient, HttpHeaders } from '@angular/common/http'
declare let $: any;
@Component({
  selector: 'app-action-center',
  templateUrl: './action-center.component.html',
  styleUrls: ['./action-center.component.css']
})

export class ActionCenterComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  @ViewChild('content2') content2: ElementRef;
  @ViewChild('sigPad') sigPad;
  Signature = "";
 // signtureImage = 'https://app.besttrainings.co.uk/signature/';

 tenantInfo: any = tenantInfo;
 signtureImage=APP_URLS.imagePath+'signature/'
  sigPadElement: any;
  context: any;
  isDrawing = false;
  img = ""
  studentId: "";
  profileDetails = {
    Signature: "",
    last_name: "",
    first_name: ""
  }

  isNoAgreement: boolean = false; // use to enable no agreement message
  isNoConditionOfferLetter:boolean = false;
  isNoApplicationProcess : boolean = false;

  downloadoffer: any
  signaturepopupopen = 'none'
  temp = [];
  showDialog: boolean = false;
  addSign: Boolean = false;
  isShow: Boolean = false;
  isShowError: Boolean = false;
  LearnerExitChecklistObj = {
    AdministratorName: ""
  }
  applicationprocess = []
  IndividualLearningAgreementobj = {
    AgeGroupRadio: "",
    ProgrammeEntryRadio: "",
    UKRPN: ""
  };
  courceidentificationObj = {
    CourceName: "",
    Level: "",
    Duration: "",
    AwardingBody: "",
    students: null
  }
  studentObj = {
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    gender: "",
    nationality: { value: "" },
    ethnic_origin: { value: "" },
    martial_status: "",
    mobile: "",
    land_line: "",
    mailing_address: { line1: "", city: "", country: "", zip: "" },
    permanent_address: { line1: "", city: "", country: "", zip: "" }
  }
  empOrgDetails = {
    nameOfEmp: "",
    keyContactName: "",
    CompanyRegNum: "",
    supervisorName: "",
    employeeOrganisationAddress: "",
    supervisorContactTelNum: "",
    employeeOrganisationPostCode: "",
    supervisorEmailId: "",
    employeeOrganisationTelephoneNum: "",
    employerOrganisationCompanySize: "",
    employeeOrganisationEmailId: "",
    businessNote: "",
    EDRSnumber: "",
    digitalSign: "",
    students: ""
  }
  AdditionalSupportNeedsQuestionsobj = {
    MedicalInfo: "",
    ExamArrangements: Boolean,
  }
  AssessorTrainingProviderObj = {
    selectedAssessor: "",
    AssessorEmail: "",
    AssessorNumber: ""
  }
  InductionLearningcentre = {
    ProgrammeContentAndDelivery: Boolean,
    EqualityAndDiversity: Boolean,
    HealthAndSafetyandWelfare: Boolean,
    DisciplinaryAndGrievanceProcedures: Boolean,
    PolicyAndAppealsProcedure: Boolean,
    TermsandConditionsLearning: Boolean,
    IssuedwithWelcomePack: Boolean,
    DateofInduction: ""
  }
  QualificationCheckedLARAObj = {
    LARAforEligibility: "",
    AwardingBodyRegistration: "",
    UniqueLearnerReference: ""
  };
  qualificationObj = {
    HaveYouDoneWithLearning: Boolean,
    englishQualification: "",
    DoesPersonalLearningRecord: Boolean,
    MathsQualification: "",
    PLRevidence: "",
    LevelQualification: "",
    IsExplainedEmployability: "",
    recordedInATLAS: "",
    ESOLQualification: "",
    IsPLRupload: Boolean,
    ComputerSkills: ""
  };
  LearningAimsMandatoryOutcomesObj = [];
  applications = {}
  verifcationobj = {
    verificationAndIdentity: []
  }
  manageEmployerInfo = {
    employementStatus: "",
    onWorkProgramm: Boolean,
    isStatusStarted: "",
    isBenefitCurrentlyClaim: "",
    lengthOfEmp: "",
    typeOfEvudenseSeen: "",
    lengthOfUnemp: "",
    issueDateOrDetails: "",
    issueDateFurtherDetails: "",
    IsJCPrequired: Boolean
  }
  modeOfDeliveryLocationObj = {
    EmployerPremises: Boolean,
    CollegePremises: Boolean,
    EmployerPremisesPostcode: "",
    CollegePremisesPostcode: "",
    OtherPremises: Boolean,
    OtherPremisesInfo: "",
    TechnicalDayRelease: Boolean,
    Workshops: Boolean,
    WorkBased: Boolean,
    FunctionalDayRelease: Boolean,
    ThursdayEveningClass: Boolean,
  }
  PreEntryInformationObj = {
    LearningProgramme: Boolean,
    Entryrequirements: Boolean,
    InitialAssessmentofSuitability: Boolean,
    SupportAvailableToMe: Boolean,
    natureAndProceduresInvolved: Boolean,
    DateLearnerReceivedInformation: "",
    EmploymentCareerProgression1: "",
    EmploymentCareerProgression2: ""
  }
  ProgressReviewobj = {
    ProgressReview: []
  }
  plannedlearningObj = {
    planedHours: "",
    learnAtHomeOrWork: "",
    learnInCenter: "",
    hoursPerWeek: "",
  }
  JournalEntryobj = {
    JournalEntry: []
  }
  onlinetest = [];
  onlinetest_student = [];
  equalityAndDiverityObj = {
    equalityGender: "",
    difficulties: [],
    GenderPronoun: "",
    SexualOrientation: "",
    PreferredMethodOfContact: "",
    LearningSupport: "",
    ESFAContactRestrictions: "",
    ESFAContactMethodRestrictions: "",
    SelectyourPrimaryDisability: [],
    WhereDidYouhearAboutus: "",
    ProviderSpecifiedLearnerDataA: "",
    ProviderSpecifiedLearnerDataB: "",
    JobReadinessStatus: ""
  }






  getPlannedReviewDatesobj = {
    Planned_ReviewDates: []
  }
  WorkPlacementHealthAndSafetyAssessmentobj = {
    Employer: "",
    NameofContact: "",
    CompetentPersonforHealthandsafety: "",
    Typeofplacement: "",
    TypeOfPlacement: "",
    NumberOfEmployees: "",
    EmployersLiabilityInsurance: Boolean,
    Policynumber: "",

    Insurer: "",

    RenewalDate: "",


    healthandsafetypolicy: Boolean,




    ConsultationWithEmployeesOnHealthSafety: Boolean,

    WelfareFacilitiesSatisfactory: Boolean,

    HealthandSafetyInductionTraining: Boolean,

    satisfactory: Boolean,

    Recorded: Boolean,

    conditionofworkingenvironment: "",

    superviseLearner: "",

    HealthAndSafetyLawposter: Boolean,

    personalProtectiveEquipment: Boolean,

    usedcorrectly: Boolean,

    accidentbook: "",

    MeansOfEscape: Boolean,

    Alarm: Boolean,
    Unobstructed: "",

    FireExitSigns: Boolean,

    Firedrills: Boolean,

    InspectedAndTested: "",

    LastTestDate: "",

    FirstAidkitSatisfactory: Boolean,

    accidentsRecorded: Boolean,

    TrainedFirstAider: Boolean,

    AwarenessOfReporting: Boolean,

    Notes: "",

    reasonablyForeseeableRisks: Boolean,

    copyAttachedTheForm: "",

    riskAssessmentTheForm: "",

    CompletedByName: "",

    CompletedBySignature: "",

    CompletedByDate: ""
  }
  IndividualLearningPlanandSignaturesObj = {
    IndividualAssessorDate: "",
    IndividualAssessorName: "",
    IndividualAssessorSignature: "",
    IndividualEmployerDate: "",
    IndividualEmployerName: "",
    IndividualEmployerSignature: "",
    IndividualLearnersDate: "",
    IndividualLearnersName: "",
    IndividualLearnersSignature: ""
  }
  SkillCoachobj = {
    SkillCoach: []
  }
  ApprenticeshipAgreementChecklistObj = {
    ApprenticesName: Boolean,
    DirectorSignature: "",
    EmployerBusinessName: null,
    EmploymentEndDate: null,
    EmploymentStartDate: null,
    FixedTermContract: null,
    HolidayEntitlement: null,
    HoursOfWork: null,
    LengthOfProbationary: null,
    LocationAndPlace: null,
    NameOfDirector: "",
    NameOfDirectorDate: null,
    NameOfEmployer: "",
    NameOfEmployerDate: null,
    NameOfSupervisor: null,
    SalaryStated: null,
    SignatureOfEmployer: "",
    SignedByEmployer: null,
    StatutorySick: null,
    students: "",
    _id: ""
  }
  CommitmentStatementObj = {
    Apprentice: "",
    ApprenticeStandard: "",
    CommitmentStatement: "",
    DateOfBirth: "",
    DutiesAsEmployee: "",
    EndDate: "",
    HoursOfWork: "",
    Level: "",
    NameOfApprenticeship: "",
    NationalInsuranceNumber: "",
    SeeILPStored: "",
    StartDate: "",
    TheApprenticeJob: "",
    TheApprenticeName: "",
    UniqueLearnerNumber: "",
    students: "",
    _id: ""
  };
  AddMilestonesobj = {
    Milestone: []
  };
  RolesAndResponcebilitiesobj = {
    RolesAndResponcebilities: []
  };
  RecommendedDurationobj = {
    RecommendedDuration: []
  };
  TotalNegotiatedPriceObj = {
    totalNegotiatedPrice: [],
    students: ""
  };

  evidencetoSupportAPLobj = {
    AttachEvidence: "",
    DateOfSignature: "",
    HowHaveYouEvidencedAPL: "",
    LevelAPL: "",
    NameofDirector: "",
    NameofStandardFramework: "",
    Signature: "",
    TNPminusAPL: "",
    ValidationAndApprovedBy: "",
    WhereThisEvidencekept: "",
    calculationsToReduceStandardFramework: "",
    students: "",
    _id: ""
  };
  agreementDeatils = {
    isClicked: false,
    isSignPending: null,
    signRequestedDate: "",
    signedDate: null,
    students: "",
    tenantLogo: "",
    _id: ""
  };

  agreementDocumentDeatils = [];

  StandardWeeklyWorkHoursobj = {
    StandardWeeklyWorkHours: [],
    students: ""
  }

  all_ConsentsObj = {
    LearningOpportunities: Boolean,
    PreferredMethodsOfContact: "",
    PermissionForABA: Boolean,
    HE_TermsAndConditions: Boolean,
    DisciplinaryMatters: Boolean,
    AcademicProgress: Boolean
  };
  studentPersonalObj = {
    DoNotContactedAboutCourses: Boolean,
    DoNotContactedSurveys: Boolean,
    DoNotContactedPost: Boolean,
    DoNotContactedphone: Boolean,
    DoNotContactedEmail: Boolean
  };
  changeLogObj = {
    changelog: []
  };
  studentList = {};
  basicAssesmentObj = {
    careeraspirations: "",
    ApprenticeshipEnhanceCareerProgression: "",
    DigitalMarketingapprenticeship: ""
  }
  conditionalofferLetter = [];

  constructor(public service: MyProfileService, private userSvc: UserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.service.setSidemenuTab("action");
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id

    this.getAgreementDetails();
    this.offerLetter();
    this.getApplicationProcess()

  }
  OpenAgreement(data) {
    if (data.signedDate) {
      this.isShow = true;
    }
    if (data.signedDate == null) {
      this.isShowError = true;
      this.isShow = true;
    }

    this.agreementDeatils = data;
    this.agreementDeatils.isClicked = true
  }
  close() {
    this.isShowError = false;
    this.isShow = false;
  }
  closeError() {
    this.isShowError = false;
    this.isShow = false;
  }
  SignAgreement() {
    this.agreementDeatils.signedDate = new Date();
    let url = APP_URLS.UpdateEmployerAgreement;
    var obj =
    {
      updateData: this.agreementDeatils
    }
    this.service.putCall(url, obj).subscribe((res: any) => {
      this.getAgreementDetails()
      if (res.details) {
        this.agreementDeatils = res.details;
        this.isShow = false;
        this.userSvc.showSuccess("Signed Successfully")
      }
    })
  }

  getAgreementDetails() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getEmployerAgreement;
    this.service.postCall(url, obj).subscribe((res: any) => {
      this.getProfileDetails()
      if (res.details) {
        this.agreementDocumentDeatils = res.details;
        this.agreementDocumentDeatils.sort((a, b) => {
          return <any>new Date(b.created) - <any>new Date(a.created);
        });
        this.isNoAgreement = (this.agreementDocumentDeatils.length ) ? false : true; //need to check if agreement is available/not
      }
    })
  }
  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.profileDetails = res.details
      if (this.Signature) {
        let url = APP_URLS.profileDetails + this.studentId;
        let Payload =
        {

          city: res.details.mailing_address.city,
          title: res.details.title,
          country: res.details.country,
          date_of_birth: res.details.date_of_birth,
          email: res.details.email,
          ethnic_origin: res.details.ethnic_origin,
          first_name: res.details.first_name,
          gender: res.details.gender,
          land_line: res.details.land_line,
          last_name: res.details.last_name,
          line1: res.details.billing_address.line1,
          mailing_address: { line1: res.details.mailing_address.line1, city: res.details.mailing_address.city, country: res.details.country, zip: res.details.mailing_address.zip },
          martial_status: res.details.martial_status,
          middle_name: res.details.middle_name,
          mobile: res.details.mobile,
          nationality: res.details.nationality,
          same: true,
          tenantId: TenantId,
          zip: res.details.mailing_address.zip,
          Signature: this.Signature
        }


        this.service.putCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            this.userSvc.showSuccess("Your profile updated successfully")
            this.service.seteditprofile('dummy')
            this.profileDetails = res.details
            this.service.setPopup(false);
          }
          if (res.errorCode) {
            this.userSvc.showError(res.errorMessage)
          }

        })
      }
      this.getApprenticeshipAgreementChecklist();
    })
  }


  getApprenticeshipAgreementChecklist() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getApprenticeshipAgreementChecklist;
    this.service.postCall(url, obj).subscribe((results: any) => {

      this.getCommitmentStatement()
      if (results.details.length > 0) {
        this.ApprenticeshipAgreementChecklistObj = results.details[0]
      } else if (results.respMessage) {
      }
    });
  }

  getCommitmentStatement() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getCommitmentStatement;
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getmilestone();
      if (results.details.length > 0) {
        this.CommitmentStatementObj = results.details[0]
      } else if (results.respMessage) {
      }
    });
  }

  getmilestone() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getMilestone
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getRolesAndResponcebilities();

      if (results.details.length > 0) {
        this.AddMilestonesobj = results.details[0]
      } else if (results.respMessage) {
      }

    });
  }

  getRolesAndResponcebilities() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getRolesAndResponcebilities
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getRecommendedDuration();
      if (results.details.length > 0) {
        this.RolesAndResponcebilitiesobj = results.details[0]
      } else if (results.respMessage) {
      }
    });
  }

  getRecommendedDuration() {

    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getRecommendedDuration
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getTotalNegotiatedPrice();
      if (results.details.length > 0) {
        this.RecommendedDurationobj = results.details[0]
      } else if (results.respMessage) {
      }
    });
  }

  getTotalNegotiatedPrice() {
    let obj = {
      _id: this.studentId
    }

    let url = APP_URLS.getTotalNegotiatedPrice
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getevidenceSupportAPL();
      if (results.details.length > 0) {
        this.TotalNegotiatedPriceObj = results.details[0]
      } else if (results.respMessage) {
      }
    });
  }

  getevidenceSupportAPL() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getEvidenceSupportAPL
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getStandardWeeklyWorkHours();
      if (results.details.length > 0) {
        this.evidencetoSupportAPLobj = results.details[0]
      } else if (results.respMessage) {
      }
    });
  }

  getStandardWeeklyWorkHours() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getStandardWeeklyWorkHours
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getIndividualLearningAgreement();
      if (results.details.length > 0) {
        this.StandardWeeklyWorkHoursobj = results.details[0]
      } else if (results && results.respMessage) {
      }
    });
  }


  getIndividualLearningAgreement() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getIndividualLearningAgreement
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getCourseIdentification();
      if (results.details && results.details.length > 0) {
        this.IndividualLearningAgreementobj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getCourseIdentification() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getcourseidentification
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getEmployerorganisationDetails();
      if (results && results.details && results.details.length > 0) {
        this.courceidentificationObj = results.details[0];
      }

    })
  }

  getEmployerorganisationDetails() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getEmpOrganisation
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getAssessorTrainingProvider();
      if (results && results.details && results.details.length > 0) {
        this.empOrgDetails = results.details[0];
      } else if (results && results.data && results.data.respMessage) {
      }
    });
  }

  getAssessorTrainingProvider() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getAssessorTrainingProvider
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getQualificationCheckedLARA();
      if (results && results.details && results.details.length > 0) {
        this.AssessorTrainingProviderObj = results.data.details[0];
      } else if (results && results.data && results.data.respMessage) {
      }
    });
  }

  getQualificationCheckedLARA() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getQualificationCheckedLARA
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getqualification();
      if (results && results.details && results.details.length > 0) {
        this.QualificationCheckedLARAObj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getqualification() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getequalification
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getVerificationofIdentitIDDocuments();
      if (results && results.details && results.details.length > 0) {
        this.qualificationObj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }

  getVerificationofIdentitIDDocuments() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getVerificationofIdentitIDDocuments
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getmanageEmpInfo();
      if (results && results.details && results.details.length > 0) {
        if (results.details[0].verificationAndIdentity.length != 0) {
          results.details[0].verificationAndIdentity.forEach(x => {
            x.IssueDate = new Date(x.IssueDate);
            x.expiryDate = new Date(x.expiryDate);
          })
        }
        this.verifcationobj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getmanageEmpInfo() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getmanageEmpInfo
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getModeOFdeliveryLocation();
      if (results && results.details && results.details.length > 0) {
        this.manageEmployerInfo = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getModeOFdeliveryLocation() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getModeOFdeliveryLocation
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getPlannedLearningHours();
      if (results && results.details && results.details.length > 0) {
        this.modeOfDeliveryLocationObj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getPlannedLearningHours() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getPlannedLearningHours
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getPreEntryInformation();
      if (results && results.details && results.details.length > 0) {
        this.plannedlearningObj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getPreEntryInformation() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getPreEntryInformation
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getequalityAndDiversity();
      if (results && results.details && results.details.length > 0) {
        this.PreEntryInformationObj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }

  getequalityAndDiversity() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getEqualityAndDiversity
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getall_Consents();
      if (results && results.details.length > 0) {
        this.equalityAndDiverityObj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }

  getall_Consents() {

    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getAllConsents
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getAdditionalSupportNeedsQuestions();
      if (results && results.details && results.details.length > 0) {
        this.all_ConsentsObj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }


  getAdditionalSupportNeedsQuestions() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getAdditionalSupportNeedsQuestions
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getPlannedReviewDates();
      if (results && results.details && results.details.length > 0) {
        this.AdditionalSupportNeedsQuestionsobj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }

  getPlannedReviewDates() {

    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getPlannedReviewDates
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getWorkPlacementHealthAndSafetyAssessment();
      if (results && results.details && results.details.length > 0) {
        this.getPlannedReviewDatesobj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }

  getWorkPlacementHealthAndSafetyAssessment() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getWorkPlacementHealthAndSafetyAssessment
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getPersonalInformation();
      if (results && results.details && results.details.length > 0) {
        this.WorkPlacementHealthAndSafetyAssessmentobj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }


  getPersonalInformation() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getPersonalInformation
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getchangeLog();
      if (results && results.details && results.details.length > 0) {
        this.studentPersonalObj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }

  getchangeLog() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getChangeLog
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getSkillCoach();
      if (results && results.details && results.details.length > 0) {
        if (results.details[0]) {
          if (results.details[0].changelog.length != 0) {
            results.details[0].changelog.forEach(x => {
              x.Date = new Date(x.Date);
            })
          }
        }
        this.changeLogObj = results.details[0];
      } else if (results && results.respMessage) {

      }
    });
  }


  getSkillCoach() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getChangeLog
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getJournalEntry();
      if (results && results.details && results.details.length > 0) {
        this.SkillCoachobj = results.details[0];
        this.SkillCoachobj.SkillCoach.forEach(x => {
          let Url = 'students?filter='
          if (x.CourseName != '') {
            Url += '&course_filter=' + x.CourseName._id;
          }
          Url += '&allEnrolledStudents=true';
          this.service.getCall(Url).subscribe((data: any) => {
            if (data && data.data) {
              x.studentList = data.data.students;
            }
          });
        });
      } else if (results && results.data && results.data.respMessage) {
      }
    });
  }


  getJournalEntry() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getJournalEntry
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getProgressReview();
      if (results && results.details && results.details.length > 0) {
        this.JournalEntryobj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getProgressReview() {

    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getProgressReview
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getLearningAimsMandatoryOutcomes();
      if (results && results.details && results.details.length > 0) {
        this.ProgressReviewobj = results.details[0];
      } else if (results && results.respMessage) {
      }
    });
  }

  getLearningAimsMandatoryOutcomes() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getProgressReview
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getBasicAssessmentRPL();
      if (results && results.details && results.details.length > 0) {
        this.LearningAimsMandatoryOutcomesObj = results.details;
      } else if (results && results.respMessage) {
      }
    });
  }


  getBasicAssessmentRPL() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getBasicAssessmentRpl
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getInductionLearningcentre()
      if (results && results.details && results.details.length > 0) {
        this.basicAssesmentObj = results.details[0];

      } else if (results && results.respMessage) {

      }
    });
  }

  getInductionLearningcentre() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getInductionLearningcentre
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getIndividualLearningPlanandSignatures();
      if (results && results.details && results.details.length > 0) {
        this.InductionLearningcentre = results.details[0];

      } else if (results && results.respMessage) {

      }
    });
  }


  getIndividualLearningPlanandSignatures() {

    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getIndividualLearningPlanandSignatures
    this.service.postCall(url, obj).subscribe((results: any) => {
      this.getStudentDetails();
      if (results && results.details && results.details.length > 0) {
        this.IndividualLearningPlanandSignaturesObj = results.details[0];

      } else if (results && results.respMessage) {

      }
    });
  }

  getStudentDetails() {
    let url = APP_URLS.getStudentDetails + this.studentId
    this.service.getCall(url).subscribe((results: any) => {
      if (results && results.details) {
        this.studentObj = results.details;

      } else if (results && results.respMessage) {

      }
    });
  }

  signaturePopupopen() {

    this.signaturepopupopen = 'block'

  }
  signaturePopupClose() {
    this.signaturepopupopen = 'none'
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  save() {
    this.img = this.sigPadElement.toDataURL("image/png");
  }

  dosignature() {
    this.img = this.sigPadElement.toDataURL("image/png");
    let url = APP_URLS.DoSignature
    var Payload = {
      signature: this.img
    }
    this.service.postCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        if (res.details) {
          this.Signature = res.details.siganture;
          this.userSvc.showSuccess("Signed Successfully")
        }
        this.getProfileDetails()
        this.signaturePopupClose()
      }
      if (res.errorCode) {
        this.userSvc.showError(res.errorMessage)
      }
    })
  }

  download() {
    var id

    var data = document.getElementById('offerletter');
    html2canvas(data).then(canvas => {
      var imgWidth = 180;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
  }
  makePdf(data) {
    let filename = data;
    // `1600239243320_file_PLZyr0ZB.pdf`;
    if(filename){
      // this.service
      // //      .download('https://app.besttrainings.co.uk/convertingtopdf/' + filename)
      // //
      // .download(APP_URLS.FILE_PATH_LINODE + 'convertingtopdf/' + filename)
      // .subscribe(blob => {
      //   const a = document.createElement('a')
      //   const objectUrl = URL.createObjectURL(blob)
      //   a.href = objectUrl
      //   a.download = filename;
      //   a.target = '_blank';
      //   a.click();
      //   URL.revokeObjectURL(objectUrl);
      // })
      
      var link = document.createElement("a");
      link.setAttribute('download', filename);
      link.setAttribute('target','_blank');
      link.href = APP_URLS.FILE_PATH_LINODE + 'convertingtopdf/' + filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    
  }
  offerLetter() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getconditionalofferletter
    this.service.postCall(url, obj).subscribe((results: any) => {

      if (results && results.details.length > 0) {
        results.details.forEach(x => {
          if (x.conditionalofferletter.length > 0) {
            x.conditionalofferletter.forEach(y => {
              this.conditionalofferLetter.push(y)
            });
          }
        });
        
      } else if (results && results.respMessage) {
      }
      this.conditionalofferLetter.sort((a, b) => {
        return <any>new Date(b.created) - <any>new Date(a.created);
      });
      this.isNoConditionOfferLetter = (this.conditionalofferLetter.length ) ? false : true;
    },
      err => {
      });
  }
  getApplicationProcess() {
    let obj = {
      _id: this.studentId
    }
    let url = APP_URLS.getapplicationprocess
    this.service.postCall(url, obj).subscribe((results: any) => {
      if (results && results.details.length > 0) {
        results.details.forEach(x => {
          if (x.applicationprocess.length > 0) {
            x.applicationprocess.forEach(y => {
              this.applicationprocess.push(y)
            });
          }
        });
      } else if (results && results.respMessage) {
      }
      this.applicationprocess.sort((a, b) => {
        return <any>new Date(b.created) - <any>new Date(a.created);
      });
      this.isNoApplicationProcess = (this.applicationprocess.length ) ? false : true;
    },
      err => {
      });
  }
  openOfferLetter(data) {

    this.downloadoffer = this.sanitizer.bypassSecurityTrustHtml(data.htmldata);
    setTimeout(() => { this.download() }, 3000);
  }


}
