import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TenantId } from '../Models/url';
@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  private subject = new Subject<any>();

  popup: Subject<any>;
  ForgotPop: Subject<any>
  token = "";
  profileDtata: Subject<any>;
  supportCompose: Subject<any>;
  ComplaintCompose: Subject<any>;
  profilePermissions: any
  notificationtab: Subject<any>;
  composeOpen: Subject<any>;
  bookappointment : Subject<any>;
  editprofile: Subject<any>;
  nexttab : Subject<any>;
  sedemenutab : Subject<any>;
  clearModel :Subject<any>;

  config = {
    base_url: "http://localhost:3700/",
    host: "localhost",
    serve: "172.104.186.153",
    port: 3000,
    serverUrlCors: "http://localhost:3700/cors/",
    serverUrl: "http://localhost:3000/api/",
    serverUrlhttps: "http://localhost:3000/api/",
    socialUrl: "http://live.test.co.uk/api/",
    imagePathOld: "http://172.104.186.153/tenantServer/modules/upload",
    imagePath: "http://localhost:3000",
    tenantConfig:
    {
      tenantId: TenantId,
      url: "http://54.147.203.20:3001/#/",
      company: "ELearning tool",
      logo: "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"
    }
  }

  headers: any;
  data: any;


  constructor(private http: HttpClient) {
    this.popup = new Subject<any>();
    this.ForgotPop = new Subject<any>();
    this.profileDtata = new Subject<any>();
    this.supportCompose = new Subject<any>();
    this.ComplaintCompose = new Subject<any>();
    this.notificationtab = new Subject<any>();
    this.composeOpen = new Subject<any>();
    this.editprofile = new Subject<any>();
    this.nexttab = new Subject<any>();
    this.bookappointment = new Subject<any>();
    this.sedemenutab = new Subject<any>();
    this.clearModel = new Subject<any>();

    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
 
  }

  getCall(url) {
    return this.http.get(url)
  }

  getCallWithHeaders(url) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.get(url,{headers})
  }

  postCall(url, payload) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.post(url, payload, { headers })
  }
  clearModelPopup(status)
  {
    this.clearModel.next({clear:status})
  }

  subClearAction()
  {
    return this.clearModel.asObservable();
  }

  postCallImg(url, payload) {
    
  
    return this.http.post(url, payload)
   
  }

  putCall(url, payload) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.put(url, payload, { headers })
  }

  setPopup(value) {
    this.popup.next(value)
  }

  getPopup() {
    return this.popup.asObservable();
  }
  setForgotPop(value) {
    this.ForgotPop.next(value)
  }
  getForgotPop() {
    return this.ForgotPop.asObservable();
  }
  setLoginPop(value) {
    this.ForgotPop.next(value)
  }
  getLoginPop() {
    return this.ForgotPop.asObservable();
  }
  setRegisterPopup(value) {
    this.ForgotPop.next(value)
  }
  getRegisterPopup() {
    return this.ForgotPop.asObservable();
  }
  setProfile(value) {
    this.profileDtata.next(value)
  }

  getProfile() {
    return this.profileDtata.asObservable();
  }
  setSupportCompose(value) {
    this.supportCompose.next(value)
  }

  getSupportCompose() {
    return this.supportCompose.asObservable();
  }

  setnotifications(value){
    this.notificationtab.next(value)
  }

  getnotifications(){
    return this.notificationtab.asObservable();
  }
  setDetails(value){
    this.composeOpen.next(value)
  }
  setDetailsIs(value){  
    return this.data=value
  }

  getDetails(){
    return this.composeOpen.asObservable();
  }

  setbookappointment(data){
    this.bookappointment.next(data)
  }

  getbookappointment(){
    return this.bookappointment.asObservable();
  }


  setComplaintCompose(value) {
    this.ComplaintCompose.next(value)
  }
  postCallwithHeaders(url, payload) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }

    return this.http.post(url, payload, { headers })
  }
  
  getComplaintCompose() {
    return this.ComplaintCompose.asObservable();
  }
  setPermissionsData(data) {
    this.profilePermissions = data
  }

  seteditprofile(data){
    this.editprofile.next(data)
  }

  geteditprofile() {
    return this.editprofile.asObservable();
  }

  setTab(data){
    this.nexttab.next(data)
  }
  getTab() {
    return this.nexttab.asObservable();
  }

  setSidemenuTab(data){
    this.sedemenutab.next(data)
  }
  getSidemenuTab() {
    return this.sedemenutab.asObservable();
  }
  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

}
