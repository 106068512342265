import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  tenantInfo: any = tenantInfo;

  userRole:any;
  constructor() { }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    
  }

}
