import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, TenantId } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit, OnDestroy {

  courselist = [];
  showDialogReg = false;
  coursetype = "";
  student_Id: any;
  activeToken: boolean = false;
  CartList = [];
  FavList = [];
  ID: string;
  detailRecivedSubscription: Subscription;

  constructor(private router: Router, public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cms: CommonService) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // }

    this.detailRecivedSubscription = route.params.subscribe(val => {
      if(typeof val.id!='undefined'){
        if(val.id!=''){
          localStorage.setItem('courseType',val.id.replace(/\-/g, ' '));
        }
      }
      this.ngOnInit();
    })
  }

  ngOnInit() {
    this.coursetype = localStorage.getItem('courseType');
    if (localStorage.getItem('token')) {
      this.activeToken = true
    }
    else {
      this.activeToken = false
    }
    this.getData();
    // let data = JSON.parse(localStorage.getItem('userDetails'))
    // if(data){
    //   this.student_Id = data._id;
    // }
    var profileData = localStorage.getItem('profilesummaryData');
    if( profileData != undefined && typeof profileData == 'string' ){
      this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
    }
  }

  getData() {
    let dataofcart = localStorage.getItem('cartdata')
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',')

    }

    // let dataofFav = localStorage.getItem('favdata');
    // if (dataofFav != null) {
    //   this.FavList = dataofFav.split(',')
    // }

    this.getList();

  }

  showType:string = 'all';
  isnoDataFound: boolean = false;
  noDataMsg:string;

  getList() {
    let url = APP_URLS.CourscesList + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course_type","value":"' + this.coursetype + '","type":"eq"}]}'
    // if (this.student_Id) {
      this.userSvc.getCallwithHeaders(url).subscribe(
        (res: any) => {
          // this.courselist = res.all
          if (res.all.length > 0) {

            this.courselist = []
            var targetArr = res[this.showType];
            for (let i = targetArr.length - 1; i >= 0; i--) {
              this.courselist.push(targetArr[i])
            }

            this.courselist.sort((a, b) => {
              return <any>new Date(b.created) - <any>new Date(a.created);
            });
            
            if( ! this.courselist.length ){
              this.isnoDataFound = true;
              if(this.showType=='all'){
                this.noDataMsg = 'No course(s) found';
              } else if(this.showType=='applied'){
                this.noDataMsg = 'No course(s) are applied';
              } else if(this.showType=='assigned'){
                this.noDataMsg = 'No course(s) are approved';
              } else if(this.showType=='favorites'){
                this.noDataMsg = 'No favourite course(s) found';
              }
            }

            if ( ! this.student_Id) {
              this.courselist.forEach(x => {
                if (this.CartList.includes(x._id)) {
                  x['text_btn'] = 'Waiting for payment'
                }
              }); 
            } 

            // res.all.forEach(x => {

            //   if (this.CartList.includes(x._id)) {
            //     x['incart'] = true
            //   }
            //   else {
            //     x['incart'] = false
            //   }

            //   if(this.FavList.includes(x._id)){
            //     x['infav'] = true
            //   }
            //   else {
            //     x['infav'] = false
            //   }
    
              // if(this.student_Id){
              //  if(x.favorites.length>0){
              //    x.favorites.forEach(y => {
              //      if(y==this.student_Id)
              //      {
              //        x.Favouritebutton="Remove Favourite"
              //      }
              //      else{
              //        x.Favouritebutton="Add As Favourite"
              //      }
              //    });
              //   }
              //   else{
              //    x.Favouritebutton="Add As Favourite"
              //   }
              //    if (x.applications.length > 0) {
              //    x.applications.forEach(y => {
              //      if (y == this.student_Id) {
              //        x.Applybutton = "Remove From Cart"
              //      }
              //      else {
              //        x.Applybutton = "Add To Cart"
              //      }
              //    });
              //  }
              //  else {
              //    x.Applybutton = "Add To Cart"
              //  }
              // }
              // else{
              // x.Favouritebutton = "Add As Favourite"
              // x.Applybutton = "Apply"
              // }
            // });

            // this.courselist = res.all
          }

        },
        err => {
        })
    // }
    // else {
    //   this.userSvc.getCallwithHeaders(url).subscribe(
    //     (res: any) => {
    //       this.courselist = res.all

    //     },
    //     err => {


    //     })
    // }


  }

  ngOnDestroy() {
    localStorage.removeItem('coursetype')
    this.detailRecivedSubscription.unsubscribe();

  }


  openIndividulaCourse(course) {
    localStorage.setItem('Individualcourse', course.slug,)
    this.router.navigate(['/' + course.slug])
  }

  // AddFav(data) {

  //   let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';
  //   if (this.student_Id) {
  //     this.service.putCall(url, {})
  //       .subscribe((data: any) => {

  //         this.userSvc.showSuccess(data.respMessage);
  //         this.getList()
  //       }, err => {

  //       })
  //   } else {
  //     this.userSvc.showError('Please register or login to continue');

  //     this.showDialogReg = true;
  //   }

  // }

  addFavorateFun(data) {

    if (this.student_Id) {
      let url = APP_URLS.applyAndFavourate + data + '?favorite=true';

      this.service.putCall(url, {})
        .subscribe((data: any) => {
          this.userSvc.showSuccess(data.respMessage);
          this.service.selectedcourse("", "");
          this.getData()
        }, err => {

        })
    } else {
      this.userSvc.showError('Please register or login to continue');
    }
  }
  
//   AddFav(data) {
//     // let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';

//     if (this.student_Id) {
//       let favarry = []
//       let url = APP_URLS.favmulti
//       favarry.push(data._id)
//       let payload = {
//         courseids : favarry,
//         target :"add"
//       }
//       this.service.putCall(url, payload)
//         .subscribe((data: any) => {

//           if(data.suc_arr.length > 0){
//             this.service.showSuccess(data.suc_arr[0]);
//           }
//           if(data.err_arr.length > 0){
//             this.service.showError(data.err_arr[0]);
//           }
//           this.getData();
//         }, err => {

//         })
//     } else {

//       // this.userservice.showError('Please register or login to continue');
//       // this.service.selectedcourse(data._id, "favourite")
//       // this.showDialogReg = true;
// this.addtofavLocalStorage(data)

//     }

//   }

  AddApply(data) {
    // this.cms.loaderSet(true);
    let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';

    if (this.student_Id) {
      this.service.putCall(url, {})
        .subscribe((data: any) => {
          // this.cms.loaderSet(false);
          this.userSvc.showSuccess(data.respMessage);
          this.getList()
        }, err => {
          // this.cms.loaderSet(false);
        })
    } else {
      this.userSvc.showError('Please register or login to continue');
      // this.cms.loaderSet(false);
      this.showDialogReg = true;
    }

  }


  addToCart(course) {

    let url = APP_URLS.addtocart + course._id + '?addtocart=true';

    if (this.student_Id) {

      this.service.putCall(url, {})
        .subscribe((data: any) => {
          if (data.suc_arr.length > 0) {
            this.userSvc.showSuccess(data.suc_arr[0]);
            this.userSvc.setCart('data')
          }
          if (data.err_arr.length > 0) {
            this.userSvc.showError(data.err_arr[0]);
          }
          this.getData()
        }, err => {

        })
    } else {
      // this.toastr.showError('Please register or login to continue');
      // this.service.selectedcourse(course._id, "addtocart")
      // this.showDialogReg=true;
      this.addtoLocalStorage(course);
    }
  }

  addtoLocalStorage(course) {
    this.CartList.push(course._id);
    localStorage.setItem("cartdata", this.CartList.toString())
    this.userSvc.setCart('data')
    this.service.showSuccess("Course Successfully added to Your Cart");
    this.getData();
  }

  goToCart(){
    this.router.navigate(['/Cart'])
  }

  addtofavLocalStorage(course) {
    this.FavList.push(course._id);
    localStorage.setItem("favdata", this.FavList.toString())
    // this.userservice.setCart('data')
    this.service.showSuccess("Course Successfully added to Favourite List");
    this.getData();
  }

  removeFav(course){ 
    if (this.student_Id) {
      let favarry = []
      let url = APP_URLS.favmulti
      favarry.push(course._id) 
      let payload = {
        courseids : favarry,
        target :"remove"
      }
      this.service.putCall(url, payload)
        .subscribe((data: any) => {
          if(data.suc_arr.length > 0){
            this.service.showSuccess(data.suc_arr[0]);
          }
          if(data.err_arr.length > 0){
            this.service.showError(data.err_arr[0]);
          }
          this.getData();
        }, err => {

        })
    } else {
    this.FavList.forEach((x, i) => {
      if (x == course._id) {
        this.FavList.splice(i, 1);
        let data = this.FavList;
        if(data.length > 0){
          localStorage.setItem('favdata', data.toString())
        }
        if(data.length == 0){
          localStorage.removeItem('favdata')
        }
       
        this.service.showSuccess("Removed from Favourite List");
        // this.userservice.setCart("cart")
        this.getData()
      
      }
    })
  }
  }


  Chapters =[];

  openCourse(Training) {
    localStorage.setItem('courseId', Training._id)
    localStorage.setItem('TrainingName', Training.name)
    localStorage.setItem('uuid', Training.resume_chapter)
    if (Training.apply_status == "assigned" && Training.resume_chapter == '') {
      this.router.navigate(['/Mycourses/', Training._id])
    }
    if (Training.apply_status == "assigned" && Training.resume_chapter != '') {
  
      this.ID = localStorage.getItem('courseId')
      let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
      this.service.getCallwithHeader(url).subscribe(
        (res: any) => {
          this.Chapters = res.cou_overview;
          this.setChaptersData();
        },
        err => {
  
        })
  
    }
  }
  
  FinalChaptersArray = [];
  
  async setChaptersData() {
    await this.Chapters.forEach((x, i) => {
      x['accordion'] = '#accordion' + i
      x['accordionID'] = 'accordion' + i
      x.child.forEach((y, index) => {
        y['childaccordion'] = '#childaccordion' + index
        y['childaccordionID'] = 'childaccordion' + index
        if (y.chapter_type == "lesson") {
          y['icon'] = "fa fa-book"
        }
        if (y.chapter_type == "assignment") {
          y['icon'] = "fa fa-code"
        }
        if (y.chapter_type == "downloads") {
          y['icon'] = "fa fa-cloud-download-alt"
        }
        if (y.chapter_type == "test" || y.chapter_type == "qatest") {
          y['icon'] = "fa fa-pencil-alt"
        }
        if (y.chapter_type == "quiz") {
          y['icon'] = "fa fa-graduation-cap"
        }
        if (y.chapter_type == "chapter") {
          y['icon'] = "fa fa-bars"
        }
      })
      this.FinalChaptersArray.push(x)
      localStorage.setItem('AllChapters', JSON.stringify(this.FinalChaptersArray))
      var Trainingid = localStorage.getItem('courseId')
      var TrainingName = localStorage.getItem('TrainingName')
      var resume_chapter = localStorage.getItem('uuid')
      this.router.navigate(['/Mychapter/', Trainingid + "-" + resume_chapter, TrainingName.replace(/\s/g, '-')])
    })
  }


}
