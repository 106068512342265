import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { TenantId } from '../../Models/url';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { json } from 'express';
import { FilterPipe } from '../../Models/filter.pipe';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  providers:[FilterPipe]
})
export class NotificationsComponent implements OnInit {
   p: number = 1;
  countPagination = 10;
  search: string;
  NotificationList = []
  studentId: any;
  file: any;
  count = 0;
  isNotificationInfo : boolean = false;
  List = [];
  ListTemp = [];

  constructor(public service: MyProfileService,
    public cms: CommonService,
    private router: Router,
    private userSvc: UserService,
    private FilterSearch : FilterPipe,
  ) {}

  ngOnInit() {
    this.service.setSidemenuTab("notifications");
    let data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.studentId = data._id;
    }
    this.getNotificationList()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.List = pageOfItems;
  }

  onClearAll(){
  }

  onChangeEvent(event: any) {
    this.ListTemp = this.FilterSearch.transform(this.NotificationList,event,'');
    this.ListTemp.forEach((y, i) => {
      y['sno'] = i + 1;
    })
    this.isNotificationInfo = ( this.ListTemp.length ) ? false : true;
  }

  getNotificationList() {
    this.count = 0;
    let url = APP_URLS.notification + '[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"active","value":"active","type":"eq"},{"key":"frontorback","value":"front","type":"or"},{"key":"frontorback","value":"frontandback","type":"or"}]}'
    this.service.getCallWithHeaders(url)
      .subscribe((res: any) => {

        this.NotificationList = res.final_noti;
        this.NotificationList.forEach((x, i) => {
          x['sno']=i+1
          if (x.open_status == 'new') {
            this.count = this.count + 1;
            this.userSvc.setNotifyCount(this.count);
             localStorage.setItem('counts',this.count.toString())
          }
          if (this.count == 0) {
            this.count = 0;
            this.userSvc.setNotifyCount(this.count)
          }

        })
        localStorage.setItem('notificationData', JSON.stringify(res));
        this.ListTemp = JSON.parse(JSON.stringify(this.NotificationList));
        this.isNotificationInfo = ( this.ListTemp.length ) ? false : true;
      }),
      err => {
      }
  }

  NotificationClick(data) {
    this.file = ""
    if (data.open_status != "new") {
      if(data.url.includes('coursetestDetails')){
        this.router.navigate(['allcertificates'])
      }
      else if(data.url.includes('?')){
        var courseid = data.url.split("?")[0]
        localStorage.setItem('courseId', courseid.split('/')[1])
        localStorage.setItem('TrainingName', data.url.split("=")[1])
        this.router.navigate([courseid])
      }
      else{
        this.router.navigate([data.url])
      }

    }
    else {
      var payload = {
        id: data.id
      }
      let url = APP_URLS.notificationClick

      this.service.postCallwithHeaders(url, payload)
        .subscribe((res: any) => {

          localStorage.removeItem('notificationData')
          this.getNotificationList()
          if(data.url.includes('coursetestDetails')){
            this.router.navigate(['allcertificates'])
          }
          else if(data.url.includes('?')){
            var courseid = data.url.split("?")[0]
            localStorage.setItem('courseId', courseid.split('/')[1])
            localStorage.setItem('TrainingName', data.url.split("=")[1])
            this.router.navigate([courseid])
          }
          else{
            this.router.navigate([data.url])
          }
        }),
        err => {

        }
    }
  }

  Notificationcross(data) {
    this.file = ""
      var payload = {
        id: data.id
      }
      let url = APP_URLS.notificationClick

      this.service.postCallwithHeaders(url, payload)
        .subscribe((res: any) => {

          localStorage.removeItem('notificationData')
          this.getNotificationList()
         
        }),
        err => {
    }
  }
}
